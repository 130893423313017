const RandExp = require('randexp')

export default {
  difference (object, base) {
    const changes = (object, base) => {
      return this._transform(object, (result, value, key) => {
        if (!this._isEqual(value, base[key])) {
          result[key] = (this._isObject(value) && this._isObject(base[key])) ? changes(value, base[key]) : value
        }
      })
    }
    return changes(object, base)
  },
  genUuid () {
    return new RandExp(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/).gen()
  },
  capitalize: s => typeof s === 'string' ? s.charAt(0).toUpperCase() + s.slice(1) : s,
  scrollToTop () {
    const mainPageEl = document.querySelector('#main-page')

    if (mainPageEl) {
      mainPageEl.scroll({
        top: 0
      })
    }
  },
  getTranslation ({ t, tc, name } = {}) {
    if (t) {
      return this.$ct(t)
    }

    if (tc) {
      return this.$ctc(...tc)
    }

    return name
  }
}
