export default {
  'account': 'compte | comptes',
  'account-name': 'nom de compte',
  'account-options': 'options du compte',
  'account-type': 'type de compte',
  'actions': 'actions',
  'activate': 'activer',
  'active-last-week': 'actifs la semaine dernière',
  'active-last-month': 'actifs le mois dernier',
  'add-user': 'ajouter un utlisateur',
  'address': 'adresse',
  'all-entities': 'toutes les entités',
  'and': 'et',
  'assignment': 'affectation | affectations',
  'back': 'retour',
  'back-to-menu': 'retour au menu',
  'balance': 'bilan',
  'balancing': 'équilibrer',
  'bank': 'banque | banques',
  'bank-account': 'compte bancaire | comptes bancaires',
  'budget': 'budget',
  'business-segment': 'secteur d\'activité',
  'close': 'fermer',
  'cancel': 'annuler',
  'caption': 'libellé',
  'category': 'catégorie | catégories',
  'cash-in': 'encaissements',
  'cash-only': 'uniquement cash',
  'cash-out': 'décaissements',
  'cash-variation': 'variation de trésorerie',
  'cashflow': 'trésorerie',
  'cashlab-back-office': 'back office de Cashlab',
  'child': 'enfant',
  'children': 'enfants',
  'classic-account-type': 'compte "classique"',
  'clear': 'effacer',
  'clearance-balance': 'apurement bilan',
  'client': 'client | clients',
  'closing-balance': 'solde de clôture',
  'color': 'couleur | couleurs',
  'comment': {
    't': 'commentaire',
    'add': 'ajouter un commentaire',
    'history': 'historique des commentaires',
    'write': 'écrivez votre commentaire'
  },
  'configure': 'configurer',
  'connection': 'se connecter',
  'country': 'pays',
  'create': 'créer',
  'create-account': 'créer le compte',
  'created-at': 'créé le {date}',
  'create-a-ticket': 'création d\'un ticket',
  'create-your-account': 'créer son compte',
  'currency': 'devise | devises',
  'date': 'date',
  'day': 'jour | jours',
  'delete': 'supprimer',
  'description': 'description',
  'discard-changes': 'annuler les modifs.',
  'disconnect': 'se déconnecter',
  'display': 'afficher',
  'display-type': 'type d\'affichage',
  'download': 'télécharger',
  'duration': 'durée exercice',
  'duplicate': 'dupliquer',
  'duplication': 'duplication',
  'duplication-type': 'type de duplication',
  'dutch': 'néerlandais',
  'drilldown': 'analyse',
  'edition': 'édition',
  'email': 'email',
  'end': 'fin',
  'end-of-subscription': 'fin d\'abonnement',
  'english': 'anglais',
  'entity': 'entité | entités',
  'entity-name': 'raison social',
  'exploitation': 'mapping',
  'faq': 'FAQ',
  'fetching-data': 'récupération des données',
  'firstname': 'prénom',
  'fiscal-start': 'exercice fiscal',
  'forecast': 'prévision',
  'free-account-type': 'compte "gratuit"',
  'french': 'français',
  'german': 'allemand',
  'graphic': 'graphique | graphiques',
  'hypothesis': 'hypothèses',
  'import': 'importation',
  'informations': 'informations',
  'initial': 'initial',
  'initial-balance': 'solde initial',
  'italy': 'italien',
  'job-function': 'titre dans l\'entreprise',
  'keyword': 'mot clé | mots clés',
  'language': 'langage',
  'lastname': 'nom',
  'links': 'liaisons',
  'list': 'liste | listes',
  'login': 'identifiant',
  'main-entity': 'entité principale',
  'module': 'module | modules',
  'month': 'mois | mois',
  'more-about': 'en savoir plus',
  'multiple-choice': 'choix multiple',
  'name': 'nom',
  'need-help': 'besoin d\'aide ?',
  'new-password': 'nouveau mot de passe',
  'next': 'suivant',
  'night': 'nuit',
  'new-version': 'nouvelle version disponible',
  'no': 'non',
  'no-option': 'aucun option',
  'none': 'aucun',
  'no-currency': 'aucune devise',
  'no-data': 'aucune donnée',
  'no-result': 'aucun résultat',
  'not-found': 'aucune donnée',
  'of': 'sur',
  'off-pl': 'hors P&L',
  'on': 'sur',
  'only-for': 'uniquement pour {name}',
  'opening-balance': 'solde d\'ouverture',
  'operation': 'opération | opérations',
  'or': 'ou',
  'obligatory': 'obligatoire',
  'page': 'page',
  'password': 'mot de passe',
  'parameters': 'paramètres',
  'period-start': 'début de période',
  'period-end': 'fin de période',
  'phone-number': 'numéro de téléphone',
  'pl': 'P&L',
  'previous': 'précédent',
  'quarter': 'trimestre',
  'required-fields': 'champs requis',
  'real': 'réel',
  'really': 'vraiment',
  'refine': 'affiner',
  'remaining-balance': 'solde restant',
  'distribution': 'repartition',
  'reportings': 'reportings',
  'restrictions': 'restrictions',
  'restriction-entities': 'restriction d\'entité (choix multiple)',
  'retype-password': 'retapez le mot de passe',
  'roles': 'rôles',
  'row': 'ligne | lignes',
  'rules': 'lois de trésorerie',
  'salaries': 'masse salariale',
  'sandbox-account-type': 'compte "sandbox"',
  'save': 'sauvegarder',
  'search': 'rechercher',
  'select-by-default': 'sélectionner par défaut',
  'send': 'envoyer',
  'send-mail': 'envoyer l\'e-mail',
  'semester': 'semestre',
  'scenarios': 'scénarios',
  'scenarios-list': 'liste des scénarios',
  'set-up': 'paramétrer',
  'settings': 'paramètres',
  'statement': 'TFT',
  'statistics': 'statistiques',
  'start': 'début',
  'schedule': 'calendaire',
  'schedule-percent': 'calendaire en %',
  'show-history': 'afficher l\'historique',
  'siret': 'SIRET',
  'spanish': 'espagnol',
  'subscription': 'abonnement',
  'swedish': 'suédois',
  'title': 'titre',
  'theme': 'thème',
  'third-party-code': 'code tiers',
  'third-party-name': 'libellé tiers',
  'to': 'à',
  'to-date': 'à date',
  'total': 'total',
  'transaction': 'transaction | transactions',
  'trial-end-date': 'date de fin période d\'essai',
  'undefined': 'non défini',
  'unit': 'unité de restitution',
  'unmapped': 'non mappé',
  'update': 'mettre à jour',
  'updated-on': 'mise à jour le',
  'use-by-default': 'utiliser par défaut',
  'use-setting': 'utiliser le réglage',
  'user': 'utilisateur | utilisateurs',
  'username-email': 'identifiant ou email',
  'validate': 'valider',
  'vat': 'TVA',
  'variance': 'écart',
  'variation': 'variation',
  'wait': 'un instant',
  'without-values': 'sans valeurs',
  'wording': 'libellé',
  'year': 'année',
  'yes': 'oui',
  'your-profil': 'Votre profil',
  '?': '?',
  '!': '!',
  ':': ':'
}
