
let isRefreshing = false
let failedQueue = []

const processQueue = (error) => {
  failedQueue.forEach(promise => {
    if (error) {
      promise.reject(error)
    }
    promise.resolve()
  })

  failedQueue = []
}

export default ({ axios, config: { globalProperties} }) => {
  axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status !== 401) {
        return Promise.reject(error)
      }

      const originalRequest = error.config

      if (!originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function(resolve, reject) {
            failedQueue.push({ resolve, reject })
          })
            .then(() => {
              return axios(originalRequest)
            })
            .catch(err => {
              return Promise.reject(err)
            })
        }

        originalRequest._retry = true
        isRefreshing = true

        return new Promise(function(resolve, reject) {
          axios
            .post('/auth/refresh')
            .then(() => {
              processQueue(null)
              resolve(axios(originalRequest))
            })
            .catch(err => {
              processQueue(err)

              globalProperties.$store.dispatch('logout')

              reject(err)
            })
            .then(() => {
              isRefreshing = false
            })
        })
      }
    }
  )
}