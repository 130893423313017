const currencies = [
  {
    id: '1a525105-8638-4cf7-8320-168c97b1ebad',
    code: 'GBP',
    symbol: '£'
  },
  {
    id: '486770e1-0a85-4f0b-aaa8-b751e7db7a75',
    code: 'EUR',
    symbol: '€'
  },
  {
    id: '4cfa687b-3283-4243-9598-adaa6d2d3c74',
    code: 'JPY',
    symbol: '¥'
  },
  {
    id: '882ed1a7-11e5-49fa-be60-0884a1690f9b',
    code: 'AUD',
    symbol: '$'
  },
  {
    id: '8e7c5bca-a53c-415e-96e4-7cbb2e26bab4',
    code: 'CNY',
    symbol: '¥'
  },
  {
    id: 'c083da93-cfa6-4d52-b742-f81affba9efe',
    code: 'CHF',
    symbol: 'CHF'
  },
  {
    id: 'c4a97850-489c-4c25-b77e-0d4ccc14e600',
    code: 'CAD',
    symbol: '$'
  },
  {
    id: 'd07106e5-4c60-4aad-ba7e-74033fea0198',
    code: 'USD',
    symbol: '$'
  },
]

export default {
  methods: {
    getCurrency (currencyId) {
      return currencies.find(({ id }) => id === currencyId)
    }
  }
}