<template>
  <div 
    v-if="lastLink"
    class="flex items-center pb-3 text-blue-500 text-sm cursor-pointer"
    @click="onClickLink(lastLink)"
  >
    <ChevronLeftIcon 
      class="h-4 w-4" 
      aria-hidden="true" 
    />
    {{ lastLink.title }}
  </div>
</template>

<script>
import clientsMixin from '../assets/mixins/clients'
import entitiesMixin from '../assets/mixins/entities'
import usersMixin from '../assets/mixins/users'

import {
  ChevronLeftIcon
} from '@heroicons/vue/outline'

export default {
  mixins: [clientsMixin, entitiesMixin, usersMixin],
  components: {
    ChevronLeftIcon
  },
  data () {
    return {
      history: [],
      historyKey: 0,
      preventClearHistory: false
    }
  },
  computed: {
    lastLink () {
      return this.history[this.history.length - 1]
    }
  },
  watch: {
    '$route.fullPath': {
      handler () {
        const fromPath = this.$route.query.from_path
        const { preventClearHistory } = this
        
        if (fromPath) {
          this.pushLink(fromPath)
        } else if (!preventClearHistory) {
          this.clearHistory()
        } 
        
        this.preventClearHistory = false
      },
      immediate: true
    }
  },
  methods: {
    async pushLink (fromPath) {
      try {
        const { history } = this
        const link = await this.getLink(fromPath)
  
        const hasLink = !!history.find(({ to }) => to === link.to)
  
        if (!hasLink) {
          this.history.push(link)
        }
      } catch (err) {
        this.clearHistory()
      }
    },
    clearHistory () {
      this.history = []
    },
    async getLink (fromPath) {
      const { historyKey } = this
      const paths = this.getPaths(fromPath)
      
      let to = fromPath, title = ''

      if (to) {
        if (paths.includes('clients')) {
          const clientId = paths[1]
  
          if (clientId) {
            let client = this.getClient(clientId)

            if (!client) {
              client = await this.fetchClient(clientId)
              
              if (!client) {
                throw new Error('back-link.client.not-found')
              }
            }
  
            title = this.$t('back-link.back-to-the-client-form') + ' ' + client.name
          } else {
            title = this.$t('back-link.back-to-client-list')
          }
        } else if (paths.includes('entities')) {
          const entityId = paths[1]
  
          if (entityId) {
            let entity = this.getEntity(entityId)

            if (!entity) {
              entity = await this.fetchEntity(entityId)
              
              if (!entity) {
                throw new Error('back-link.entity.not-found')
              }
            }
  
            title = this.$t('back-link.back-to-the-entity-form') + ' ' + entity.name
          }
        } else if (paths.includes('users')) {
          const userId = paths[1]
  
          if (userId) {
            let user = this.getUser(userId)

            if (!user) {
              user = await this.fetchUser(userId)
              
              if (!user) {
                throw new Error('back-link.user.not-found')
              }
            }
  
            title =  this.$t('back-link.back-to-the-user-form')  + ' ' + user.firstname + ' ' + user.lastname
          }
        }
      }

      this.historyKey++

      return {
        historyKey,
        to,
        title
      }
    },
    getPaths (fromPath) {
      return fromPath.split('/').filter(path => path)
    },
    onClickLink (link) {
      this.preventClearHistory = true

      this.$router.push(link.to)

      this.history = this.history.filter(({ historyKey }) => historyKey !== link.historyKey)
    }
  }
}
</script>