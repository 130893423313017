import { createStore } from 'vuex'

import auth from './auth'
import businessSegment from './business-segment'
import client from './client'
import country from './country'
import fetching from './fetching'
import navigation from './navigation'
import role from './role'
import user from './user'

export default (app, router) => createStore({
  modules: {
    auth: auth(app, router),
    businessSegment: businessSegment(app, router),
    client: client(app, router),
    country: country(app, router),
    fetching: fetching(app, router),
    navigation: navigation(app, router),
    role: role(app, router),
    user: user(app, router)
  }
})
