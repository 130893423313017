<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
      <img 
        src="/logo-dark.png" 
        alt="Cashlab"
        class="w-40 mx-auto"
      >
      <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900 capitalize-first">
        {{ $t('cashlab-back-office') }}
      </h2>
      <form class="mt-8 space-y-6" action="#" method="POST" @submit="onSubmit">
        <div>
          <ui-input 
            id="email"
            :placeholder="$t('username-email')"
            @input="onInput('name', $event)"
          />
          <ui-input 
            class="mt-2"
            id="password"
            type="password"
            :placeholder="$t('password')"
            @input="onInput('password', $event)"
          />
        </div>
        <ui-button
          type="submit"
          :name="$t('connection')"
          class="w-full"
        />
      </form>
    </div>
  </div>
</template>

<script>
import authMixin from '../assets/mixins/auth'
import businessSegmentMixin from '../assets/mixins/business-segment'
import countryMixin from '../assets/mixins/country'

import UiInput from '../components/ui-input.vue'
import UiButton from '../components/ui-button.vue'

export default {
  mixins: [authMixin, businessSegmentMixin, countryMixin],
  components: {
    UiInput,
    UiButton
  },
  data() {
    return {
      name: null,
      password: null
    }
  },
  methods: {
    onInput (type, { target: { value } }) {
      this[type] = value
    },
    async onSubmit (event) {
      event.preventDefault()

      const { name, password } = this

      try {
        await this.signIn({
          name,
          password
        })

        this.fetchBusinessSegments()
        this.fetchCountries()

        this.$router.push('/clients')
      } catch (e) {
        this.$notify.error()
      }
    }
  }
}
</script>

<style>

</style>