export default {
  'account-type-classic-description': 'pour une utilisation normale.',
  'account-type-free-description': 'pour une utilisation limitée.',
  'account-type-sandbox-description': 'pour une utilisation démo/test.',
  'add': 'ajouter un client',
  'create-account': 'créer un compte client',
  'create-success': 'Le client {name} a bien été créé !',
  'create-title': 'formulaire de création compte client',
  'delete': 'supprimer le client {name}',
  'delete-description': 'Êtes-vous sûr de vouloir supprimer ce client ? Toutes vos données seront définitivement supprimées de nos serveurs pour toujours. Cette action ne peut pas être annulée.',
  'delete-success': 'Le client {name} a bien été supprimé !',
  'duplicate': 'client à dupliquer',
  'export-clients': 'télécharger la liste des clients',
  'export-entity': 'télécharger la liste des entités',
  'list': 'liste des clients',
  'informations': 'information du client {name}'
}
