<template>
  <h1 v-if="client" class="pb-4 text-lg font-medium">
    {{ $ct('users.create-title', { name: client.name }) }}
  </h1>
  <ui-form 
    :sections="form.sections"
    :save-button-name="$t('users.create-button')"
    @submit="onSubmitForm"
  />
</template>

<script>
import UiForm from '../components/ui-form.vue'

import clientsMixin from '../assets/mixins/clients'
import entitiesMixin from '../assets/mixins/entities'
import usersMixin from '../assets/mixins/users'
import formMixin from '../assets/mixins/form'

export default {
  title () {
    return this.$ct('users.create')
  },
  mixins: [clientsMixin, entitiesMixin, usersMixin, formMixin],
  components: {
    UiForm
  },
  data () {
    return {
      client: {},
      entities: [],
      form: {
        sections: [
          {
            key: 'informations',
            title: this.$ct('informations'),
            inputs: [
              {
                key: 'accountName',
                label: this.$t('account-name'),
                type: 'text',
                value: '',
                required: true
              },
              {
                key: 'password',
                label: this.$t('password'),
                type: 'password',
                defaultType: 'password',
                value: '',
                required: true
              },
              {
                key: 'retypePassword',
                label: this.$t('retype-password'),
                type: 'password',
                defaultType: 'password',
                value: '',
                required: true
              },
              {
                key: 'lastname',
                label: this.$t('lastname'),
                type: 'text',
                value: '',
                required: true
              },
              {
                key: 'firstname',
                label: this.$t('firstname'),
                type: 'text',
                value: '',
                required: true
              },
              {
                key: 'email',
                label: this.$t('email'),
                type: 'email',
                value: '',
                required: true
              },
              {
                key: 'phoneNumber',
                label: this.$t('phone-number'),
                type: 'text',
                value: ''
              },
              {
                key: 'jobFunction',
                label: this.$t('job-function'),
                type: 'list',
                value: ''
              },
              {
                key: 'user-role',
                title: this.$ct('roles'),
                type: 'list',
                value: []
              },
              {
                key: 'entities',
                type: 'list',
                value: [],
                list: [],
                label: this.$ct('restriction-entities'),
                multiple: true
              },
              {
                key: 'sendMail',
                label: this.$t('send-mail'),
                type: 'toggle',
                value: true
              }
            ]
          }
        ]
      }
    }
  },
  computed: {
    clientId () {
      return this.$route.query.client_id
    },
    clientsInput () {
      return this.getInput('clients')
    },
    accountNameInput () {
      return this.getInput('accountName')
    },
    passwordInput () {
      return this.getInput('password')
    },
    retypePasswordInput () {
      return this.getInput('retypePassword')
    },
    firstnameInput () {
      return this.getInput('firstname')
    },
    lastnameInput () {
      return this.getInput('lastname')
    },
    emailInput () {
      return this.getInput('email')
    },
    phoneNumberInput () {
      return this.getInput('phoneNumber')
    },
    jobFunctionInput () {
      return this.getInput('jobFunction')
    },
    entitiesInput () {
      return this.getInput('entities')
    },
    roleInput () {
      return this.getInput('user-role')
    },
    sendMailInput () {
      return this.getInput('sendMail')
    },
    userRoles () {
      return this.$store.getters.getRoles
    }
  },
  async created () {
    const { clientId, entitiesInput } = this

    this.client = await this.fetchClient(clientId)
    this.entities = await this.fetchEntities(clientId)

    entitiesInput.value = []
    entitiesInput.list = this.entities
  },
  methods: {
    async onSubmitForm () {
      try {
        const { clientId, client, accountNameInput, passwordInput, firstnameInput, lastnameInput, jobFunctionInput, emailInput, phoneNumberInput, entitiesInput, sendMailInput, roleInput } = this
        const accountName = accountNameInput.value
        const password = passwordInput.value
        const firstName = firstnameInput.value
        const lastName = lastnameInput.value
        const email = emailInput.value
        const phoneNumber = phoneNumberInput.value
        const jobFunction = jobFunctionInput.value
        const clientIds = [client.id]
        const role = roleInput.value.map(( {value} ) => value)
        const restrictionEntityIds = entitiesInput.value
        const sendMail = sendMailInput.value

        const newUser = {
          accountName,
          password,
          role,
          firstName,
          lastName,
          email,
          phoneNumber,
          jobFunction,
          clientIds,
          restrictionEntityIds,
          sendMail,
          languageId: 'b6cca73c-9324-4157-9000-c71f2a207265'
        }

        const { id } = await this.createUser(newUser)

        newUser.id = id

        this.addUser(newUser)

        // translation
        this.$notify({ type: 'success', text: this.$t('users.create-success', { name: firstName + ' ' + lastName }) })

        this.$router.push('/users/' + id + '?from_path=/clients/' + clientId)
      } catch (err) {
        // translation
        this.$notify.error()
      }
    }
  }
}
</script>

<style>

</style>