export default () => ({
  state () {
    return {
      list: [
        'admin',
        'dashboard'
      ]
    }
  },
  getters: {
    getRoles (state) {
      return state.list
    }
  }
})
