import { createRouter, createWebHistory } from 'vue-router'
import SignIn from './pages/sign-in'
import Profile from './pages/profile'
import Clients from './pages/clients'
import ClientsCreate from './pages/clients.create'
import ClientsView from './pages/clients.view'
import EntitiesView from './pages/entities.view'
import EntitiesCreate from './pages/entities.create'
import EntitiesDuplication from './pages/entities.duplication'
import UsersView from './pages/users.view'
import UsersCreate from './pages/users.create'
import Statistics from './pages/statistics'

import globalUtilities from './assets/mixins/global-utilities'

const routes = [
  { path: '/', redirect: '/sign-in' },
  { 
    path: '/sign-in', 
    component: SignIn, 
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem('IS_AUTHENTICATED')) {
        next({ path: '/clients' })
      } else {
        next()
      }
    } 
  },
  { path: '/profile', component: Profile, meta: { requiresAuth: true } },
  { path: '/clients', component: Clients, meta: { requiresAuth: true } },
  { path: '/clients/create', component: ClientsCreate, meta: { requiresAuth: true } },
  { path: '/clients/:id', component: ClientsView, meta: { requiresAuth: true } },
  { path: '/entities/:id', component: EntitiesView, meta: { requiresAuth: true } },
  { path: '/entities/create', component: EntitiesCreate, meta: { requiresAuth: true } },
  { path: '/entities/duplication', component: EntitiesDuplication, meta: { requiresAuth: true } },
  { path: '/users/:id', component: UsersView, meta: { requiresAuth: true } },
  { path: '/users/create', component: UsersCreate, meta: { requiresAuth: true } },
  { path: '/statistics', component: Statistics, meta: { requiresAuth: true } }
]

const router = createRouter({
  routes,
  history: createWebHistory(),
  scrollBehavior () {
    globalUtilities.scrollToTop()
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('IS_AUTHENTICATED')) {
      next({ path: '/sign-in' })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
