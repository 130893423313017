<template>
  <div class="px-4 py-3 flex flex-col items-center border-t border-gray-200 sm:px-6">
    <div class="flex-1 flex justify-between sm:hidden">
      <button
        class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        @click="onClickPrevious"
      >
        {{ $ct('previous') }}
      </button>
      <button
        class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
        @click="onClickNext"
      >
        {{ $ct('next') }}
      </button>
    </div>
    <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
      <div>
        <p class="text-sm mr-2 text-gray-700">
          {{ $ct('page') }}
          <span 
            v-test="'current-page'"
            class="font-medium"
          >
            {{ currentPage }}
          </span>
          {{ $t('of') }}
          <span 
            v-test="'max-page'"
            class="font-medium"
          >
            {{ maxPage }}
          </span>
        </p>
      </div>
      <div>
        <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          <button 
            v-test="'start-button'"
            class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            @click="onClickStart"
          >
            <span class="sr-only">{{ $ct('start') }}</span>
            <ChevronDoubleLeftIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <button 
            v-test="'previous-button'"
            class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            @click="onClickPrevious"
          >
            <span class="sr-only">{{ $ct('previous') }}</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <button 
            v-test="'next-button'"
            class="relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            @click="onClickNext"
          >
            <span class="sr-only">{{ $ct('next') }}</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
          </button>
          <button 
            v-test="'end-button'"
            class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            @click="onClickEnd"
          >
            <span class="sr-only">{{ $ct('end') }}</span>
            <ChevronDoubleRightIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronLeftIcon, ChevronRightIcon, ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/vue/solid'

export default {
  emits: ['list:paginate'],
  props: {
    list: {
      type: Array,
      default: () => ([])
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    }
  },
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDoubleLeftIcon,
    ChevronDoubleRightIcon
  },
  data () {
    return {
      currentPage: 1
    }
  },
  computed: {
    minPage () {
      return 1
    },
    maxPage () {
      return Math.ceil(this.list.length / this.limit)
    }
  },
  watch: {
    'page': {
      handler (page) {
        this.currentPage = page

        this.emitPaginatedList()
      },
      immediate: true
    },
    'list' () {
      this.currentPage = 1
      
      this.emitPaginatedList()
    }
  },
  methods: {
    onClickStart () {
      this.currentPage = 1

      this.emitPaginatedList()
    },
    onClickPrevious () {
      const { minPage, currentPage } = this

      if (currentPage > minPage) {
        this.currentPage--

        this.emitPaginatedList()
      }
    },
    onClickNext () {
      const { maxPage, currentPage } = this

      if (currentPage < maxPage) {
        this.currentPage++

        this.emitPaginatedList()
      }
    },
    onClickEnd () {
      this.currentPage = this.maxPage

      this.emitPaginatedList()
    },
    emitPaginatedList () {
      const { list, currentPage, limit } = this
      
      const paginatedList = list.slice((currentPage - 1) * limit, currentPage * limit)

      this.$emit('list:paginate', paginatedList)
    }
  }
}
</script>