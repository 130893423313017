function setAttribute (el, name = '', value = '') {
  el.setAttribute('data-test-' + name, value)
}

function start (el, binding) {
  if (!Array.isArray(binding.value)) {
    binding.value = [binding.value]
  }

  binding.value.forEach((data) => {
    let name = ''
    let value = ''
    
    if (typeof data === 'object') {
      name = data.name
      value = data.value
    } else {
      name = data
    }

    setAttribute(el, name, value)
  })
}

function beforeMount (el, binding) {
  start(el, binding)
}

function updated (el, binding) {
  start(el, binding)
}

function unmounted () {

}

export default {
  beforeMount,
  updated,
  unmounted
}