import config from '../../config'
import global from './global'
import notification from './notification'
import errors from './errors'

const pages = require.context('./pages', true, /[a-z]\w+\.(js)$/)
const components = require.context('./components', true, /[a-z]\w+\.(js)$/)
const translations = config([pages, components])

export default {
  ...translations,
  ...global,
  notification,
  errors
}