<template>
  <label 
    v-if="title"
    class="block text-sm font-medium text-gray-700 capitalize-first"
  >
    {{ title }}
  </label>
  <ui-options
    :options="userRole.options"
    @select="$emit('select', $event)"
  />
</template>

<script>
import UiOptions from './ui-options.vue'

export default {
  props: {
    defaultRole: {
      type: Array,
      default: () => ([]),
      validate (defaultRole) {
        return Array.isArray(defaultRole)
      }
    },
    title: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UiOptions
  },
  data () {
    return {
      userRole: {
        options: []
      }
    }
  },
  computed: {
    userRoles () {
      return this.$store.getters.getRoles
    }
  },
  created () {
    this.initRoleList()
  },
  methods: {
    initRoleList () {
      const userRoleOptions = this.userRoles.map((userRole) => {
        return {
          key: userRole,
          value: userRole,
          label: userRole,
          checked: this.defaultRole.includes(userRole)
        }
      })

      this.userRole.options = userRoleOptions
    }
  }
}
</script>