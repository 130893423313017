<template>
  <ui-select 
    :label="label ? label : displayLabel ? $ct('language') : ''"
    :options="lang.list"
    :default-selected="defaultLang"
    :disabled="disabled"
    class="w-60"
  />
</template>

<script>
import UiSelect from './ui-select.vue'

// translation
const langs = [
  {
    key: 'fr',
    label: 'Français',
    value: 'fr'
  },
  {
    key: 'en',
    label: 'Anglais',
    value: 'en'
  }
]

export default {
  props: {
    defaultLang: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UiSelect
  },
  data () {
    return {
      lang: {
        list: []
      }
    }
  },
  created () {
    this.initLangList()
  },
  methods: {
    initLangList () {
      this.lang.list = langs
    }
  }
}
</script>