const _cloneDeep = require('lodash.clonedeep')
const _isEmpty = require('lodash.isempty')
const _isEqual = require('lodash.isequal')
const _isObject = require('lodash.isobject')
const _transform = require('lodash.transform')
const _merge = require('lodash.merge')
const _difference = require('lodash.difference')
const _differenceWith = require('lodash.differencewith')

export default {
  _cloneDeep,
  _isEmpty,
  _isEqual,
  _isObject,
  _transform,
  _merge,
  _capitalize: s => s.charAt(0).toUpperCase() + s.slice(1),
  _difference,
  _differenceWith
}
