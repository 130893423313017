<template>
  <ui-select 
    :label="label ? label : displayLabel ? $ct('fiscal-year-duration') : ''"
    :options="duration.list"
    :default-selected="defaultDuration"
    :disabled="disabled"
    class="w-40"
  />
</template>

<script>
import UiSelect from './ui-select.vue'

const durations = [
  12,
  24,
  36
]

export default {
  props: {
    defaultDuration: {
      type: [Number, String],
      default: 0
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UiSelect
  },
  data () {
    return {
      duration: {
        list: []
      }
    }
  },
  created () {
    this.initDurationList()
  },
  methods: {
    initDurationList () {
      const durationList = durations.map((duration, index) => {
        return {
          key: index,
          value: duration,
          label: duration
        }
      })

      this.duration.list = durationList
    }
  }
}
</script>