export default {
  'add': 'add an entity',
  'back-to-clients': 'back to the list of clients',
  'copy': 'entity to be copied',
  'create': 'create an entity',
  'create-button': 'create the entity',
  'create-title': 'form to create an entity for {name}',
  'create-success': 'the entity {name} has been created !',
  'delete': 'delete the {name} entity ?',
  'delete-success': 'the entity {name} has been deleted !',
  'duplication': 'duplicate entity',
  'duplication-title': 'duplicate entity form',
  'duplication-total-description': 'is about operational mapping, cash flow mapping, relationships and cash flow laws.',
  'duplication-mapping-description': 'concerns the operation mapping and the connections.',
  'duplication-rules-description': 'is only concerned with cash laws.',
  'duplication-success': 'the {name} entity has been duplicated !',
  'informations': 'information about the entity {name}',
  'modify': 'entity to be modified',
  'new': 'new entity?'
}
