export default ({ config: { globalProperties: { $api, $i18n } } }) => ({
  state () {
    return {
      list: []
    }
  },
  getters: {
    getBusinessSegments (state) {
      return state.list
    }
  },
  actions: {
    async fetchBusinessSegments ({ commit }) {
      const { data: businessSegments } = await $api.businessSegment.get($i18n.global.locale)

      commit('setBusinessSegments', businessSegments)
    }
  },
  mutations: {
    setBusinessSegments (state, businessSegments) {
      state.list = businessSegments
    }
  }
})
