<template>
  <div class="flex">
    <ui-select
      v-test="'month-select'" 
      :label="$ct('month')"
      :options="month.list"
      :default-selected="month.selected"
      @select="onSelectOption('month', $event)"
    />
    <ui-select
      v-if="!disableYear"
      v-test="'year-select'" 
      :label="$ct('year')"
      :options="year.list"
      :default-selected="year.selected"
      class="ml-2"
      @select="onSelectOption('year', $event)"
    />
  </div>
</template>

<script>
import UiSelect from './ui-select.vue'

export default {
  props: {
    defaultMonth: {
      type: [String, Number],
      default: ''
    },
    defaultYear: {
      type: [String, Number],
      default: ''
    },
    disableYear: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UiSelect
  },
  data () {
    return {
      month: {
        list: [],
        selected: ''
      },
      year: {
        list: [],
        selected: ''
      }
    }
  },
  created () {
    this.initMonthList()
    this.initYearList()
    this.setSelectedMonth()
    this.setSelectedYear()
  },
  watch: {
    'defaultMonth' () {
      this.setSelectedMonth()
    },
    'defaultYear' () {
      this.setSelectedYear()
    }
  },
  methods: {
    initMonthList () {
      const monthList = this.$moment.months().map((month, index) => {
        return {
          key: index + 1,
          value: month,
          label: month
        }
      })

      this.month.list = monthList
    },
    initYearList () {
      const yearList = [...Array(50).keys()].map((_, index) => {
        const year = 2000 + index

        return {
          key: year,
          value: year,
          label: year
        }
      })

      this.year.list = yearList
    },
    setSelectedMonth () {
      const { defaultMonth } = this

      let value = ''

      if (defaultMonth) {
        const { list } = this.month
  
        const selectedMonth = list.find(({ key }) => key === Number(defaultMonth))
  
        value = selectedMonth?.value
      }

      this.month.selected = value
    },
    setSelectedYear () {
      const { defaultYear } = this

      let value = ''

      if (defaultYear) {
        const { list } = this.year
  
        const selectedYear = list.find(({ value }) => value === Number(defaultYear))
  
        value = selectedYear?.value
      }

      this.year.selected = value
    },
    onSelectOption (key, option) {
      this.$emit('select', { key, option })
    }
  }
}
</script>