<template>
  <h1 class="pb-4 text-lg font-medium capitalize-first">
    {{ $t('entities.create-title', { name: client.name }) }}
  </h1>
  <ui-form 
    :sections="form.sections"
    :save-button-name="$t('entities.create-button')"
    @submit="onSubmitForm"
  />
</template>

<script>
import UiForm from '../components/ui-form.vue'

import clientsMixin from '../assets/mixins/clients'
import entitiesMixin from '../assets/mixins/entities'
import formMixin from '../assets/mixins/form'

export default {
  title () {
    return this.$ct('entities.create')
  },
  mixins: [formMixin, clientsMixin, entitiesMixin],
  components: {
    UiForm
  },
  data () {
    return {
      client: {},
      form: {
        sections: [
          {
            key: 'informations',
            title: this.$t('informations'),
            inputs: [
              {
                key: 'name',
                label: this.$t('name'),
                type: 'text',
                value: '',
                required: true
              },
              {
                key: 'siret',
                label: this.$t('siret'),
                type: 'text',
                value: ''
              },
              {
                key: 'address',
                label: this.$t('address'),
                type: 'text',
                value: ''
              },
              {
                key: 'businessSegment',
                label: this.$t('business-segment'),
                type: 'list',
                value: ''
              },
              {
                key: 'country',
                label: this.$t('country'),
                type: 'list',
                value: ''
              },
              {
                key: 'precisionUnit',
                label: this.$t('unit'),
                type: 'list',
                value: ''
              },
              {
                key: 'monthFiscalStart',
                label: this.$t('fiscal-start'),
                type: 'month-selector',
                month: ''
              }
            ]
          }
        ]
      }
    }
  },
  computed: {
    clientId () {
      return this.$route.query.client_id
    },
    clientsInput () {
      return this.getInput('clients')
    },
    nameInput () {
      return this.getInput('name')
    },
    siretInput () {
      return this.getInput('siret')
    },
    addressInput () {
      return this.getInput('address')
    },
    businessSegmentInput () {
      return this.getInput('businessSegment')
    },
    countryInput () {
      return this.getInput('country')
    },
    precisionUnitInput () {
      return this.getInput('precisionUnit')
    },
    monthFiscalStartInput () {
      return this.getInput('monthFiscalStart')
    }
  },
  async created () {
    this.client = await this.fetchClient(this.clientId)
  },
  methods: {
    async onSubmitForm () {
      try {
        const { clientId, nameInput, siretInput, addressInput, businessSegmentInput, countryInput, precisionUnitInput, monthFiscalStartInput } = this

        const name = nameInput.value
        const siret = siretInput.value
        const address = addressInput.value
        const countryId = countryInput.value
        const monthFiscalStart = this.$moment().month(monthFiscalStartInput.month).format('M')
        const precisionUnit = precisionUnitInput.value
        const businessSegment = businessSegmentInput.value

        const newEntity = {
          clientId,
          name,
          siret,
          address,
          countryId,
          monthFiscalStart,
          precisionUnit,
          businessSegmentId: businessSegment
        }

        const { id } = await this.createEntity(newEntity)

        this.$notify({ type: 'success', text: this.$t('entities.create-success', { name: newEntity.name }) })

        this.$router.push('/entities/' + id + '?from_path=/clients/' + clientId)
      } catch (err) {
        this.$notify.error()
      }
    }
  }
}
</script>

<style>

</style>