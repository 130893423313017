<template>
  <fieldset class="space-y-5">
    <div 
      v-for="option in options"
      :key="option.key"
      class="relative flex items-start"
    >
      <div class="flex items-center h-5">
        <input
          v-test="{
            name: 'input',
            value: option.key
          }"
          :id="'ui-option-' + option.key" 
          :aria-describedby="option.key + '-description'" 
          :name="option.label"
          :checked="option.checked"
          type="checkbox" 
          class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
          @input="onInput($event, option)"
        />
      </div>
      <div class="ml-3 text-sm">
        <label 
          :for="'ui-option-' + option.key" 
          class="font-medium text-gray-700 capitalize-first"
        >
          {{ option.label }}
        </label>
        <span 
          :id="option.key + '-description'" 
          class="text-gray-500"
        >
          <span class="sr-only">{{ option.label }}</span> {{ option.description }}
        </span>
      </div>
    </div>
  </fieldset>
</template>

<script>
export default {
  emits: ['select'],
  props: {
    options: {
      type: Array,
      default: () => ([])
    }
  },
  methods: {
    onInput (event, option) {
      option.checked = event.target.checked
      
      this.$emit('select', this.options.filter(({checked}) => checked))
    }
  }
}
</script>

<style>

</style>