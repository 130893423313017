<template>
  <Listbox 
    as="div"
    v-test="'list'"
    :disabled="disabled"
    :class="{
      'opacity-60': disabled
    }"
    class="w-40"
  >
    <ListboxLabel
      v-if="label"
      v-test="'label'" 
      class="block text-sm font-medium text-gray-700 capitalize-first"
    >
      {{ label }}
    </ListboxLabel>
    <div class="mt-1 relative">
      <ListboxButton
        v-test="'button'" 
        class="h-10 bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        <span class="block truncate">
          {{ selectedLabel }}
        </span>
        <span class="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <SelectorIcon 
            class="h-5 w-5 text-gray-400" 
            aria-hidden="true" 
          />
        </span>
        <XIcon
          v-if="displayClearButton && hasSelected"
          class="absolute top-1 right-5 h-8 w-8 p-2 cursor-pointer"
          @click.stop="onClickClearButton"
        />
      </ListboxButton>
      <transition 
        leave-active-class="transition ease-in duration-100" 
        leave-from-class="opacity-100" 
        leave-to-class="opacity-0"
      >
        <ListboxOptions class="flex flex-col absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
          <input 
            type="search"
            :placeholder="$ct('search')"
            class="
              rounded
              text-sm
              m-2
            "
            @input="onInputSearch"
          >
          <ListboxOption 
            as="template" 
            v-for="option in filteredOptions"
            v-test="{
              name: 'option',
              value: option.key
            }"
            :key="option.key" 
            :value="option.value" 
            v-slot="{ active }"
            @click="onClickOption(option)"
          >
            <li 
              :class="active ? 'text-white bg-indigo-600' : 'text-gray-900'"
              class="cursor-default select-none relative py-2 pl-3 pr-9"
            >
              <span 
                :class="isSelected(option) ? 'font-semibold' : 'font-normal'"
                class="block truncate"
              >
                {{ option.label }}
              </span>
              <span 
                v-if="isSelected(option)" 
                :class="active ? 'text-white' : 'text-indigo-600'"
                class="absolute inset-y-0 right-0 flex items-center pr-4"
              >
                <CheckIcon 
                  class="h-5 w-5" 
                  aria-hidden="true"
                />
              </span>
            </li>
          </ListboxOption>
          <li
            v-if="!hasOptions"
            v-test="'no-option'"
            class="cursor-default text-red-800 select-none relative py-2 pl-3 pr-9"
          >
            <span
              class="block truncate font-normal"
            >
              {{ $ct('no-option') }}...
            </span>
          </li>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>

<script>
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue'
import { CheckIcon, SelectorIcon, XIcon } from '@heroicons/vue/solid'

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => ([])
    },
    defaultSelected: {
      type: [String, Number, Array],
      default: ''
    },
    displayClearButton: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Listbox,
    ListboxButton,
    ListboxLabel,
    ListboxOption,
    ListboxOptions,
    CheckIcon,
    SelectorIcon,
    XIcon
  },
  data () {
    return {
      selected: [],
      search: ''
    }
  },
  computed: {
    selectedLabel () {
      const { options, selected } = this

      if (selected.length > 1) {
        // translation
        return selected.length + ' sélectionnés'
      } else if (selected.length > 0) {
        return options.find(({ value }) => selected.includes(value))?.label
      }

      return ''
    },
    hasSelected () {
      return this.selected.length > 0
    },
    hasOptions () {
      return this.options.length > 0
    },
    filteredOptions () {
      const { options, search } = this

      return options.filter(
        option => String(option.label).toLowerCase().includes(search.toLowerCase())
      )
    }
  },
  watch: {
    'defaultSelected' () {
      this.setDefaultSelected()
    }
  },
  created () {
    this.setDefaultSelected()
  },
  methods: {
    setDefaultSelected () {
      const { defaultSelected } = this

      let value = ''

      if (defaultSelected) {
        if (Array.isArray(defaultSelected)) {
          value = defaultSelected
        } else {
          value = [defaultSelected]
        }
      }

      this.selected = value
    },
    onClickOption (option) {
      const { selected, multiple } = this

      if (multiple) {
        const isIncluded = selected.includes(option.value)
  
        if (isIncluded) {
          this.selected = selected.filter(value => value !== option.value)
        } else {
          selected.push(option.value)
        }
        
        this.$emit('select', {
          ...option,
          selected: !isIncluded
        })
      } else {
        this.selected = [option.value]

        this.$emit('select', {
          ...option,
          selected: true
        })
      }
    },
    onClickClearButton () {
      this.selected = []
      
      this.$emit('clear')
    },
    isSelected (option) {
      return this.selected.includes(option.value)
    },
    onInputSearch ({ target: { value } }) {
      this.search = value
    }
  }
}
</script>

<style>

</style>