export default {
  methods: {
    async fetchClients () {
      return await this.$store.dispatch('fetchClients')
    },
    async fetchClient (clientId) {
      return await this.$store.dispatch('fetchClient', clientId)
    },
    async createClient (client) {
      return await this.$store.dispatch('createClient', client)
    },
    async updateClient (clientId, data) {
      return await this.$store.dispatch('updateClient', { clientId, data })
    },
    getAllClients () {
      return this.$store.getters.getAllClients
    },
    getClients (clientIds) {
      return this.$store.getters.getClients(clientIds)
    },
    getClient (clientId) {
      return this.$store.getters.getClient(clientId)
    },
    getClientsByUserId (userId) {
      return this.$store.getters.getClientsByUserId(userId)
    },
    getClientByEntityId (entityId) {
      return this.$store.getters.getClientByEntityId(entityId)
    },
    getClientCreatedAt (client) {
      return this.$moment(client.createdAt, 'DD-MM-YYYY').format('ll')
    },
    getSubscribeEndDate (client) {
      return client.subscribeEndDate ? this.$moment(client.subscribeEndDate, 'DD-MM-YYYY').format('MMM YYYY') : '-'
    },
    getModulesUsePercentageStatistics (client) {
      const modules = [
        {
          key: 'bankData',
          name: 'Bank Data'
        },
        {
          key: 'forecast',
          name: 'Forecast'
        },
        {
          key: 'metrics',
          name: 'Metrics'
        },
        {
          key: 'simulation',
          name: 'Simulation'
        }
      ]

      const statistics = client.statistics?.modules?.usePercentage || {}

      return modules.map((module) => {
        const value = statistics[module.key]

        return {
          ...module,
          value
        }
      }).sort((a, b) => b.value - a.value)
    },
    addClient (client) {
      this.$store.dispatch('addClient', client)
    },
    async deleteClient (clientId) {
      await this.$store.dispatch('deleteClient', clientId)
    }
  }
}