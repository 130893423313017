<template>
  <h1 class="pb-4 text-lg font-medium capitalize-first">
    {{ $t('entities.duplication-title') }}
  </h1>
  <ui-form 
    :sections="form.sections"
    :save-button-name="$t('duplicate')"
    @input="onInputForm"
    @input:clear="onClearInputForm"
    @submit="onSubmitForm"
  />
</template>

<script>
import UiForm from '../components/ui-form.vue'

import clientsMixin from '../assets/mixins/clients'
import entitiesMixin from '../assets/mixins/entities'
import usersMixin from '../assets/mixins/users'
import formMixin from '../assets/mixins/form'

export default {
  title () {
    return this.$ct('entities.duplication')
  },
  mixins: [clientsMixin, entitiesMixin, usersMixin, formMixin],
  components: {
    UiForm
  },
  data () {
    return {
      client: {},
      entities: [],
      form: {
        sections: [
          {
            key: 'entities',
            title: 'Entités',
            inputs: [
              {
                id: 'entity-copy',
                key: 'entities',
                type: 'list',
                value: '',
                list: [],
                label: this.$t('entities.copy'),
                required: true
              },
              {
                id: 'entity-target',
                key: 'entities',
                type: 'list',
                value: '',
                list: [],
                label: this.$t('entities.modify'),
                displayClearButton: true,
                disabled: true,
                required: false
              }
            ]
          },
          {
            key: 'informations',
            title: this.$t('entities.new'),
            display: true,
            inputs: [
              {
                key: 'name',
                label: this.$t('name'),
                type: 'text',
                value: '',
                required: true
              }
            ]
          },
          {
            key: 'type',
            title: this.$t('duplication.type'),
            display: false,
            inputs: [
              {
                key: 'duplication-type',
                label: '',
                type: 'radio',
                selected: 'all',
                required: true,
                options: [
                  {
                    key: 'all',
                    value: 'all',
                    label: this.$t('total'),
                    description: this.$t('entities.duplication-total-description')
                  },
                  {
                    key: 'exploitationMapping',
                    value: 'exploitationMapping',
                    label: this.$t('mapping'),
                    description: this.$t('entities.duplication-mapping-description')
                  },
                  {
                    key: 'rule',
                    value: 'rule',
                    label: this.$t('rules'),
                    description: this.$t('entities.duplication-rules-description')
                  }
                ]
              }
            ]
          }
        ]
      }
    }
  },
  computed: {
    clientId () {
      return this.$route.query.client_id
    },
    entitiesSection () {
      return this.getSection('entities')
    },
    informationsSection () {
      return this.getSection('informations')
    },
    typeSection () {
      return this.getSection('type')
    },
    entityCopyInput () {
      return this.entitiesSection.inputs.find(({ id }) => id === 'entity-copy')
    },
    entityTargetInput () {
      return this.entitiesSection.inputs.find(({ id }) => id === 'entity-target')
    },
    nameInput () {
      return this.getInput('name')
    },
    duplicationTypeInput () {
      return this.getInput('duplication-type')
    }
  },
  async created () {
    const { clientId } = this

    this.client = await this.fetchClient(clientId)
    this.entities = await this.fetchEntities(clientId)

    this.entityCopyInput.list = this.entities

    this.updateEntityTargetList()
  },
  methods: {
    updateSectionDisplay () {
      const { entityTargetInput, informationsSection, typeSection } = this

      if (entityTargetInput.value) {
        informationsSection.display = false
        typeSection.display = true
      } else {
        informationsSection.display = true
        typeSection.display = false
      }
    },
    updateEntityTargetList () {
      this.entityTargetInput.list = this.entityCopyInput.list.filter(({ id }) => id !== this.entityCopyInput.value)
    },
    onInputForm ({ input }) {
      const { entities, entityTargetInput } = this

      if (input.id === 'entity-copy') {
        if (input.value && entities.length > 1) {
          entityTargetInput.disabled = false
        } else {
          entityTargetInput.disabled = true
        }

        this.updateEntityTargetList()
      } else if (input.id === 'entity-target') {
        this.updateSectionDisplay()
      }
    },
    onClearInputForm ({ input }) {
      if (input.id === 'entity-target') {
        this.updateSectionDisplay()
      }
    },
    async onSubmitForm () {
      try {
        const { clientId, entityCopyInput, entityTargetInput, nameInput, duplicationTypeInput } = this

        const name = nameInput.value
        const entityToCopy = entityCopyInput.list.find(({ id }) => id === entityCopyInput.value)
        const entityTarget = entityTargetInput.list.find(({ id }) => id === entityTargetInput.value)
        const duplicationType = duplicationTypeInput.selected

        if (!entityTarget) {
          const { id } = await this.duplicateEntity({
            entityIdSrc: entityToCopy.id,
            name
          })

          this.$notify({ type: 'success', text: this.$t('entities.duplication-success', { name: entityToCopy.name }) })

          this.$router.push('/entities/' + id + '?from_path=/clients/' + clientId)
        } else {
          await this.duplicateEntity({
            entityIdSrc: entityToCopy.id,
            entityIdTarget: entityTarget.id,
            duplicationType
          })

          this.$notify({ type: 'success', text: this.$t('entities.duplication-success', { name: entityToCopy.name }) })

          this.$router.push('/entities/' + entityTarget.id + '?from_path=/clients/' + clientId)
        }
      } catch (err) {
        this.$notify.error()
      }
    }
  }
}
</script>

<style>

</style>