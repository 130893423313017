let lodash = {}

function transformClientData (client) {
  client = lodash._cloneDeep(client)

  const attrs = [
    {
      key: 'id',
      default: ''
    },
    {
      key: 'name',
      default: ''
    },
    {
      key: 'hasSubscription',
      default: false
    },
    {
      key: 'subscribeEndDate',
      default: ''
    },
    {
      key: 'createdAt',
      default: ''
    },
    {
      key: 'statistics',
      default: {}
    },
    {
      key: 'entities',
      default: []
    },
    {
      key: 'userIds',
      default: []
    }
  ]

  attrs.forEach((attr) => {
    let value = client[attr.key] !== undefined ? client[attr.key] : attr.default
    
    if (attr.key === 'entities') {
      value = value.map(transformEntityData)
    }

    if (attr.key === 'userIds') {
      value = client.users?.map(({ id }) => id) || []

      delete client.users
    }

 
    client[attr.key] = value
  })
  
  return client
}

function transformEntityData (entity) {
  const attrs = [
    {
      key: 'id',
      default: ''
    },
    {
      key: 'name',
      default: ''
    },
    {
      key: 'siret',
      default: ''
    },
    {
      key: 'address',
      default: ''
    },
    {
      key: 'countryId',
      default: ''
    },
    {
      key: 'currencyId',
      default: ''
    },
    {
      key: 'fiscalStart',
      default: ''
    },
    {
      key: 'precisionUnit',
      default: ''
    },
    {
      key: 'businessSegment',
      default: ''
    },
    {
      key: 'createdAt',
      default: ''
    },
    {
      key: 'userIds',
      default: []
    },
    {
      key: 'userCount',
      default: 0
    }
  ]

  attrs.forEach((attr) => {
    let value = entity[attr.key] !== undefined ? entity[attr.key] : attr.default
 
    entity[attr.key] = value
  })

  return entity
}

export default ({ config: { globalProperties: { $api, $lodash, $moment } } }) => {
  lodash = $lodash

  return {
    state () {
      return {
        list: [
          // { 
          //   id: '0011b499-dc2a-47fb-b846-80c8e2c5c18a',
          //   name: 'Euler Hermes',
          //   subscribeStatus: 'active',
          //   subscribeEndDate: '2022-03',
          //   createdAt: '2020-04-10 13:40:53.510348+02',
          //   statistics: {
          //     users: {
          //       total: 10,
          //       activeLastWeek: 5,
          //       activeLastMonth: 8
          //     },
          //     modules: {
          //       usePercentage: {
          //         bankData: 23,
          //         forecast: 35,
          //         metrics: 12,
          //         simulation: 30
          //       }
          //     }
          //   },
          //   entities: [
          //     {
          //       id: '2cbe033b-dc60-4f2b-a6d1-13c45364c44b',
          //       name: 'Euler Hermes',
          //       siret: '',
          //       address: '', 
          //       duration: '36', 
          //       countryId: '76deffe5-388a-4689-b7a3-099f3c48c4a4', 
          //       currencyId: '486770e1-0a85-4f0b-aaa8-b751e7db7a75',
          //       fiscalStart: '2020-01-01', 
          //       precisionUnit: 'thousand', 
          //       businessSegment: 'Electronique',
          //       userIds: ['0f2c0d48-c65d-4062-bec4-09702a21cbfb'],
          //       createdAt: '2020-04-10 13:41:10.889005+02'
          //     }
          //   ],
          //   userIds: ['0f2c0d48-c65d-4062-bec4-09702a21cbfb']
          // },
          // { 
          //   id: '13555938-4b04-4753-925a-51c31c06de64',
          //   name: 'CAT AMANIA',
          //   subscribeStatus: 'active',
          //   subscribeEndDate: '2021-11',
          //   createdAt: '2019-10-10 11:57:42.741739+02',
          //   statistics: {
          //     users: {
          //       total: 2,
          //       activeLastWeek: 1,
          //       activeLastMonth: 1
          //     },
          //     modules: {
          //       usePercentage: {
          //         bankData: 7,
          //         forecast: 28,
          //         metrics: 35,
          //         simulation: 30
          //       }
          //     }
          //   },
          //   entities: [
          //     {
          //       id: 'b84230ac-a131-4174-bae0-093b35a03cad',
          //       name: 'CAT AMANIA',
          //       siret: '',
          //       address: '31 RUE BOBBY SANDS\n44800 SAINT HERBLAIN', 
          //       duration: '24', 
          //       countryId: '76deffe5-388a-4689-b7a3-099f3c48c4a4', 
          //       currencyId: '486770e1-0a85-4f0b-aaa8-b751e7db7a75',
          //       fiscalStart: '2020-01-01', 
          //       precisionUnit: 'unit', 
          //       businessSegment: 'Biens personnels et récréatifs',
          //       userIds: ['7e6042ba-d69c-4017-abf9-62bd3ab51549'],
          //       createdAt: '2019-10-10 11:57:42.741739+02'
          //     },
          //     {
          //       id: 'f9fd79bb-c835-4814-92a4-7a2f17747226',
          //       name: 'CAT PARTICIPATIONS',
          //       siret: '',
          //       address: '31 RUE BOBBY SANDS\n44800 SAINT HERBLAIN', 
          //       duration: '24', 
          //       countryId: '76deffe5-388a-4689-b7a3-099f3c48c4a4', 
          //       currencyId: '486770e1-0a85-4f0b-aaa8-b751e7db7a75',
          //       fiscalStart: '2020-01-01', 
          //       precisionUnit: 'unit', 
          //       businessSegment: 'Biens personnels et récréatifs',
          //       userIds: ['7e6042ba-d69c-4017-abf9-62bd3ab51549'],
          //       createdAt: '2021-03-11 16:35:15.303495+01'
          //     },
          //     {
          //       id: '94547915-e105-4328-bcff-5f3ce7b1cce5',
          //       name: 'AMANIA SHARE',
          //       siret: '',
          //       address: '31 RUE BOBBY SANDS\n44800 SAINT HERBLAIN', 
          //       duration: '24', 
          //       countryId: '76deffe5-388a-4689-b7a3-099f3c48c4a4', 
          //       currencyId: '486770e1-0a85-4f0b-aaa8-b751e7db7a75',
          //       fiscalStart: '2020-01-01', 
          //       precisionUnit: 'unit', 
          //       businessSegment: 'Services aux entreprises',
          //       userIds: ['7e6042ba-d69c-4017-abf9-62bd3ab51549'],
          //       createdAt: '2019-10-10 11:57:42.741739+02'
          //     },
          //     {
          //       id: '01eeb4f2-6cd6-42df-ab78-277f4c2ef6e3',
          //       name: 'CAT AMANIA LUXEMBOURG',
          //       siret: '',
          //       address: '2 PLACE DE PARIS \nL-2314 LUXEMBOURG', 
          //       duration: '24', 
          //       countryId: '76deffe5-388a-4689-b7a3-099f3c48c4a4', 
          //       currencyId: '486770e1-0a85-4f0b-aaa8-b751e7db7a75',
          //       fiscalStart: '2020-01-01', 
          //       precisionUnit: 'unit', 
          //       businessSegment: 'Industrie pharmaceutique',
          //       userIds: ['7e6042ba-d69c-4017-abf9-62bd3ab51549'],
          //       createdAt: '2019-10-10 11:57:42.741739+02'
          //     },
          //     {
          //       id: '1792aea5-7bfa-48f1-8233-09d30c705eab',
          //       name: 'SCI ATLANTIQUE',
          //       siret: '',
          //       address: '31 RUE BOBBY SANDS\n44800 SAINT HERBLAIN', 
          //       duration: '24', 
          //       countryId: '76deffe5-388a-4689-b7a3-099f3c48c4a4', 
          //       currencyId: '486770e1-0a85-4f0b-aaa8-b751e7db7a75',
          //       fiscalStart: '2020-01-01', 
          //       precisionUnit: 'unit', 
          //       businessSegment: 'Industrie pharmaceutique',
          //       userIds: ['7e6042ba-d69c-4017-abf9-62bd3ab51549'],
          //       createdAt: '2019-10-10 11:57:42.741739+02'
          //     }
          //   ],
          //   userIds: ['7e6042ba-d69c-4017-abf9-62bd3ab51549']
          // },
          // { 
          //   id: '062a80c9-030b-4674-89b7-6182d5f1393e',
          //   name: 'Lowell Partners',
          //   subscribeStatus: 'not active',
          //   subscribeEndDate: null,
          //   createdAt: '2020-04-11 15:57:07.40076+02',
          //   statistics: {
          //     users: {
          //       total: 5,
          //       activeLastWeek: 5,
          //       activeLastMonth: 5
          //     },
          //     modules: {
          //       usePercentage: {
          //         bankData: 97,
          //         forecast: 0,
          //         metrics: 3,
          //         simulation: 0
          //       }
          //     }
          //   },
          //   entities: [
          //     {
          //       id: 'b0b00b8a-f1c0-46de-8d7a-d7ea0298040e',
          //       name: 'Lowell Partners',
          //       siret: '',
          //       address: '', 
          //       duration: '36', 
          //       countryId: '76deffe5-388a-4689-b7a3-099f3c48c4a4', 
          //       currencyId: '486770e1-0a85-4f0b-aaa8-b751e7db7a75',
          //       fiscalStart: '2020-01-01', 
          //       precisionUnit: 'thousand', 
          //       businessSegment: 'Services aux collectivités',
          //       userIds: ['b109a7ff-ee89-492c-a9bb-f3f033c7efa2'],
          //       createdAt: '2020-04-11 15:57:55.216764+02'
          //     }
          //   ],
          //   userIds: ['b109a7ff-ee89-492c-a9bb-f3f033c7efa2']
          // }
        ]
      }
    },
    getters: {
      getAllClients (state) {
        return state.list
      },
      getClients: (state) => (clientIds) => {
        return state.list.filter(({ id }) => clientIds.includes(id))
      },
      getClient: (state) => (clientId) => {
        return state.list.find(({ id }) => id === clientId)
      },
      getClientsByUserId: (state) => (userId) => {
        return state.list.filter(({ userIds }) => userIds.includes(userId))
      },
      getClientByEntityId: (state) => (entityId) => {
        return state.list.find(({ entities }) => entities.find(({ id }) => id === entityId))
      },
      getAllEntities (state) {
        return state.list.map(({ entities }) => entities).flat()
      },
      getEntities: (state, { getEntity }) => (entityIds) => {
        return entityIds.map(getEntity)
      },
      getEntity: (state) => (entityId) => {
        for (const client of state.list) {
          const entity = client.entities.find(({ id }) => id === entityId)
  
          if (entity) {
            return entity
          }
        }
      },
      getEntitiesByClientId: (state, { getClient }) => (clientId) => {
        return getClient(clientId)?.entities
      },
      getEntitiesByClientIds: (state, { getClients }) => (clientIds) => {
        return getClients(clientIds).map(({ entities }) => entities)
      },
      getEntitiesByUserId: (state, { getUser, getEntities }) => (userId) => {
        return getEntities(getUser(userId)?.entityIds)
      }
    },
    actions: {
      async fetchClients ({ commit, dispatch }) {
        dispatch('pushFetchingKey', 'clients')
  
        let { data: clients } = await $api.client.get()
  
        clients = clients.map(transformClientData)
        
        commit('setClients', clients)
  
        dispatch('removeFetchingKey', 'clients')

        return clients
      },
      async fetchClient ({ dispatch }, id) {
        dispatch('pushFetchingKey', 'client')
  
        let { data: client } = await $api.client.get(id)
  
        const newClient = dispatch('addClient', client)
  
        dispatch('removeFetchingKey', 'client')
  
        return newClient
      },
      async createClient ({ commit, dispatch }, client) {
        dispatch('pushFetchingKey', 'create-client')
  
        const { data } = await $api.client.create(client)
  
        const newClient = transformClientData(data)

        commit('addClient', newClient)
  
        dispatch('removeFetchingKey', 'create-client')

        return newClient
      },
      async updateClient ({ commit, dispatch, getters }, { clientId, data }) {
        dispatch('pushFetchingKey', 'update-client')

        if (data.subscribeEndDate) {
          data.subscribeEndDate = $moment(data.subscribeEndDate.year + '-' + data.subscribeEndDate.month, 'YYYY-MM').format()
        }
  
        await $api.client.put(clientId, data)

        const client = getters.getClient(clientId)
  
        commit('updateClient', { client, data })
  
        dispatch('removeFetchingKey', 'update-client')
      },
      async deleteClient ({ commit, dispatch }, clientId) {
        dispatch('pushFetchingKey', 'delete-client')
  
        await $api.client.delete(clientId)
  
        commit('removeClient', clientId)
  
        dispatch('removeFetchingKey', 'delete-client')
      },
      addClient ({ commit, getters, dispatch }, client) {
        const newClient = transformClientData(client)
  
        const clientInList = getters.getClient(client.id)
  
        if (clientInList) {
          commit('updateClient', { client: clientInList, data: newClient })
        } else {
          commit('addClient', newClient)
        }

        client.users.forEach((user) => {
          dispatch('addUser', user)
          dispatch('pushClientIdToUser', { clientId: client.id, userId: user.id })
        })

        return newClient
      },
      removeClient ({ commit, getters }, clientId) {
        const users = getters.getUsersByClientId(clientId)
  
        commit('removeClient', { clientId, users })
      },
      // Entity is not stored. We should ?
      async fetchEntities ({ dispatch }, clientId) {
        dispatch('pushFetchingKey', 'entities')
  
        const { data: entities } = await $api.entity.get({ clientId })
  
        dispatch('removeFetchingKey', 'entities')
  
        return entities
      },
      async fetchEntity ({ dispatch }, id) {
        dispatch('pushFetchingKey', 'entity')
  
        const { data: entity } = await $api.entity.get({ entityId: id })
  
        dispatch('removeFetchingKey', 'entity')
  
        return entity
      },
      async createEntity ({ dispatch }, entity) {
        dispatch('pushFetchingKey', 'create-entity')
  
        const { data } = await $api.entity.create(entity)
  
        const newEntity = transformEntityData(data)
  
        dispatch('removeFetchingKey', 'create-entity')

        return newEntity
      },
      async updateEntity ({ dispatch }, { entityId, data }) {
        dispatch('pushFetchingKey', 'update-entity')

        if (data.fiscalStart) {
          data.fiscalStart = $moment(data.fiscalStart.year + '-' + data.fiscalStart.month, 'YYYY-MM').format()
        }

        await $api.entity.put(entityId, data)
  
        dispatch('removeFetchingKey', 'update-entity')
      },
      async duplicateEntity ({ dispatch }, data) {
        dispatch('pushFetchingKey', 'duplucate-entity')

        const { data: entity } = await $api.entity.duplicate(data)
  
        dispatch('removeFetchingKey', 'duplucate-entity')

        return entity
      },
      async deleteEntity ({ dispatch }, entityId) {
        dispatch('pushFetchingKey', 'delete-entity')
  
        await $api.entity.delete(entityId)

        dispatch('removeFetchingKey', 'delete-entity')
      },
      removeEntity ({ commit, getters }, entityId) {
        const client = getters.getClientByEntityId(entityId)
        const users = getters.getUsersByEntityId(entityId)
  
        commit('removeEntity', { entityId, client, users })
      }
    },
    mutations: {
      setClients (state, clients) {
        state.list = clients
      },
      addClient (state, client) {
        state.list.push(client)
      },
      updateClient (state, { client, data }) {
        Object.keys(client).forEach((key) => {
          const value = data[key] === undefined ? client[key] : data[key]
  
          client[key] = value
        })
      },
      removeClient (state, { clientId, users }) {
        state.list = state.list.filter(({ id }) => id !== clientId)
        
        users?.forEach((user) => {
          user.clientIds = user.clientIds.filter(id => id !== clientId)
        })
      },
      removeEntity (state, { entityId, client, users }) {
        client.entities = client.entities.filter(({ id }) => id !== entityId)
        
        users.forEach((user) => {
          user.entityIds = user.entityIds.filter(id => id !== entityId)
        })
      },
    }
  }
}