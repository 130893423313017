<template>
  <div v-if="isNotApp" >
    <router-view />
    <notifications position="bottom right" class="text-lg" />
  </div>
  <main-layout 
    v-else
  >
    <router-view />
    <notifications position="bottom right" class="text-lg" />
    <ui-loader />
  </main-layout>
</template>

<script>
import authMixin from './assets/mixins/auth'
import navigationMixin from './assets/mixins/navigation'
import businessSegmentMixin from './assets/mixins/business-segment'
import countryMixin from './assets/mixins/country'

import MainLayout from './components/main-layout.vue'
import UiLoader from './components/ui-loader.vue'

export default {
  mixins: [authMixin, navigationMixin, businessSegmentMixin, countryMixin],
  components: {
    MainLayout,
    UiLoader
  },
  data () {
    return {
      sidebarOpen: false
    }
  },
  created () {
    if (this.isAuthenticated) {
      this.fetchBusinessSegments()
      this.fetchCountries()
    }
  }
}
</script>