export default ({ axios }) => {
  return {
    get (clientId) {
      return axios.get('/client' + (clientId ? '/' + clientId : ''))
    },
    create (client) {
      let accountTypePath = ''

      const { accountType } = client

      if (accountType === 'classic') {
        accountTypePath = '/classic'
      } else if (accountType === 'free') {
        accountTypePath = '/free-trial'
      } else if (accountType === 'sandbox') {
        accountTypePath = '/sandbox'
      }

      if (!accountTypePath) {
        throw new Error('api.client.create.account-type.not-available')
      }
      
      const newClient = {
        ...client
      }

      delete newClient.accountType
      
      return axios.post('/client' + accountTypePath, newClient)
    },
    put (clientId, data) {
      return axios.put('/client/' + clientId, data)
    },
    delete (clientId) {
      return axios.delete('/client/' + clientId)
    }
  }
}