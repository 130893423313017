export default {
  computed: {
    isAuthenticated () {
      return localStorage.getItem('IS_AUTHENTICATED')
    },
    getCurrentUser () {
      return this.$store.getters.getCurrentUser
    },
    getCurrentUserId () {
      return this.getCurrentUser.id
    },
    getCurrentUserName () {
      return this.getCurrentUser.name
    }
  },
  methods: {
    async signIn (form) {
      return await this.$store.dispatch('signIn', form)
    },
    async logout () {
      return await this.$store.dispatch('logout')
    }
  }
}