<template>
  <ui-select 
    :label="label ? label : displayLabel ? $ct('unit') : ''"
    :options="precisionUnit.list"
    :default-selected="defaultPrecisionUnit"
    :disabled="disabled"
    class="w-60"
  />
</template>

<script>
import UiSelect from './ui-select.vue'

// translation
const precisionUnits = [
  {
    value: 'unit',
    name: '€'
  },
  {
    value: 'thousand',
    name: 'K€'
  },
  {
    value: 'million',
    name: 'M€'
  }
]

export default {
  props: {
    defaultPrecisionUnit: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UiSelect
  },
  data () {
    return {
      precisionUnit: {
        list: []
      }
    }
  },
  created () {
    this.initPrecisionUnitList()
  },
  methods: {
    initPrecisionUnitList () {
      const precisionUnitList = precisionUnits.map(({ value, name }, index) => {
        return {
          key: index,
          value: value,
          label: name
        }
      })

      this.precisionUnit.list = precisionUnitList
    }
  }
}
</script>