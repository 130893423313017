import clientsMixins from './clients'
import currenciesMixins from './currencies'

export default {
  mixins: [currenciesMixins, clientsMixins],
  methods: {
    async fetchEntities (clientId) {
      return await this.$store.dispatch('fetchEntities', clientId)
    },
    async fetchEntity (entityId) {
      return await this.$store.dispatch('fetchEntity', entityId)
    },
    async createEntity (entity) {
      return await this.$store.dispatch('createEntity', entity)
    },
    async updateEntity (entityId, data) {
      return await this.$store.dispatch('updateEntity', { entityId, data })
    },
    async duplicateEntity (data) {
      return await this.$store.dispatch('duplicateEntity', data)
    },
    getAllEntities () {
      return this.$store.getters.getAllEntities
    },
    getEntities (entityIds) {
      return this.$store.getters.getEntities(entityIds)
    },
    getEntity (entityId) {
      return this.$store.getters.getEntity(entityId)
    },
    getEntitiesByEntityId (clientId) {
      return this.$store.getters.getEntitiesByClientId(clientId)
    },
    getEntitiesByClientIds (clientIds) {
      return this.$store.getters.getEntitiesByClientIds(clientIds)
    },
    getEntitiesByUserId (userId) {
      return this.$store.getters.getEntitiesByUserId(userId)
    },
    getFiscalStart (entity) {
      return entity.fiscalStart ? this.$moment(entity.fiscalStart, 'YYYY-MM-DD').format('ll') : '-'
    },
    getPrecisionUnit (entity) {
      return entity.precisionUnit || '-'
    },
    getEntityCreatedAt (entity) {
      return this.$moment(entity.createdAt, 'DD-MM-YYYY').format('ll')
    },
    deleteEntity (entityId) {
      this.$store.dispatch('deleteEntity', entityId)
    },
    removeEntity (entityId) {
      this.$store.dispatch('removeEntity', entityId)
    }
  }
}