<template>
  <div class="h-screen flex overflow-hidden bg-gray-100">
    <TransitionRoot 
      as="template" 
      :show="sidebarOpen"
    >
      <Dialog 
        as="div" 
        :open="sidebarOpen"
        static 
        class="fixed inset-0 flex z-40 md:hidden" 
        @close="sidebarOpen = false" 
      >
        <TransitionChild 
          as="template" 
          enter="transition-opacity ease-linear duration-300" 
          enter-from="opacity-0" 
          enter-to="opacity-100" 
          leave="transition-opacity ease-linear duration-300" 
          leave-from="opacity-100" 
          leave-to="opacity-0"
        >
          <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>
        <TransitionChild 
          as="template" 
          enter="transition ease-in-out duration-300 transform" 
          enter-from="-translate-x-full" 
          enter-to="translate-x-0" 
          leave="transition ease-in-out duration-300 transform" 
          leave-from="translate-x-0" 
          leave-to="-translate-x-full"
        >
          <div class="relative flex-1 flex flex-col max-w-xs w-full bg-gray-800">
            <TransitionChild 
              as="template" 
              enter="ease-in-out duration-300" 
              enter-from="opacity-0" 
              enter-to="opacity-100" 
              leave="ease-in-out duration-300" 
              leave-from="opacity-100" 
              leave-to="opacity-0"
            >
              <div class="absolute top-0 right-0 -mr-12 pt-2">
                <button 
                  class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" 
                  @click="sidebarOpen = false"
                >
                  <span class="sr-only">
                    {{ $t('main-layout.close-sidebar') }}
                  </span>
                  <XIcon 
                    class="h-6 w-6 text-white" 
                    aria-hidden="true" 
                  />
                </button>
              </div>
            </TransitionChild>
            <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
              <div class="px-4 text-white text-xl text-center">
                Cashlab - Back office
              </div>
              <div class="p-4">
                <div class="border-t border-gray-700" />
              </div>
              <nav class="mt-5 px-2 space-y-1">
                <router-link 
                  v-for="link in displayableLinks"
                  :key="link.t" 
                  :to="link.to" 
                  :class="[
                    isCurrentLink(link) ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 
                    'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                  ]"
                >
                  <component 
                    :is="link.icon" 
                    :class="[
                      isCurrentLink(link) ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300', 
                      'mr-4 flex-shrink-0 h-6 w-6'
                    ]" 
                    aria-hidden="true" 
                  />
                  {{ getTranslation(link) }}
                </router-link>
              </nav>
            </div>
            <div class="flex-shrink-0 flex bg-gray-700 p-4">
              <router-link 
                to="/profile" 
                class="flex-shrink-0 group block"
              >
                <div class="flex items-center">
                  <CogIcon  class="text-white h-6 w-6"/>
                  <p class="ml-3 text-base font-medium text-white">
                    {{ getCurrentUserName }}
                  </p>
                </div>
              </router-link>
            </div>
          </div>
        </TransitionChild>
        <div class="flex-shrink-0 w-14">
          <!-- Force sidebar to shrink to fit close icon -->
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:flex-shrink-0">
      <div class="flex flex-col w-64">
        <!-- Sidebar component, swap this element with another sidebar if you like -->
        <div class="flex flex-col h-0 flex-1 bg-gray-800">
          <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <h1 class="px-4 text-white text-xl text-center">
              Cashlab - Back office
            </h1>
            <div class="p-4">
              <div class="border-t border-gray-700" />
            </div>
            <nav class="mt-5 flex-1 px-2 bg-gray-800 space-y-1">
              <router-link 
                v-for="link in displayableLinks" 
                :key="link.t" 
                :to="link.to" 
                :class="[
                  isCurrentLink(link) ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                  'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                ]" 
              >
                <component 
                  :is="link.icon" 
                  :class="[
                    isCurrentLink(link) ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300', 
                    'mr-3 flex-shrink-0 h-6 w-6'
                  ]" 
                  aria-hidden="true" 
                />
                {{ getTranslation(link) }}
              </router-link>
            </nav>
          </div>
          <div class="flex-shrink-0 flex bg-gray-700 p-4">
            <router-link 
              to="/profile" 
              class="flex-shrink-0 w-full group block"
            >
              <div class="flex items-center"> 
                <CogIcon  class="text-white h-6 w-6"/>
                <p class="ml-3 text-base font-medium text-white">
                  {{ getCurrentUserName }}
                </p>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="flex flex-col w-0 flex-1 overflow-hidden">
      <div class="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
        <button 
          class="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500" 
          @click="sidebarOpen = true"
        >
          <span class="sr-only">
            {{ $t('main-layout.open-sidebar') }}
          </span>
          <MenuIcon 
            class="h-6 w-6" 
            aria-hidden="true" 
          />
        </button>
      </div>
      <main 
        id="main-page"
        class="flex-1 relative z-0 overflow-y-auto focus:outline-none"
      >
        <div class="py-6 max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
          <main-header />
          <div class="py-6">
            <BackLink />
            <slot />
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import authMixin from '../assets/mixins/auth'
import navigationMixin from '../assets/mixins/navigation'

import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'

import { CogIcon, MenuIcon, XIcon } from '@heroicons/vue/outline'

import MainHeader from './main-header.vue'
import BackLink from '../components/back-link.vue'

export default {
  mixins: [authMixin, navigationMixin],
  props: {
    navigationList: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    MainHeader,
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    BackLink,
    CogIcon,
    MenuIcon,
    XIcon,
  },
  data () {
    return {
      sidebarOpen: false
    }
  }
}
</script>