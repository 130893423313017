<template>
  <ui-select
    :label="label ? label : displayLabel ? $ct('country') : ''"
    :options="country.list"
    :default-selected="defaultCountry"
    :disabled="disabled"
    class="w-96"
  />
</template>

<script>
import countryMixin from '../assets/mixins/country'

import UiSelect from './ui-select.vue'

export default {
  mixins: [countryMixin],
  props: {
    defaultCountry: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UiSelect
  },
  data () {
    return {
      country: {
        list: []
      }
    }
  },
  computed: {
    countries () {
      return this.getCountries()
    }
  },
  watch: {
    'countries': {
      handler () {
        this.initCountryList()
      },
      immediate: true
    }
  },
  methods: {
    initCountryList () {
      this.country.list = this.countries.map(({ id, name }) => {
        return {
          key: id,
          value: id,
          label: name
        }
      })
    }
  }
}
</script>