<template>
  <div>
    Statistics
  </div>
</template>

<script>
export default {
  title: 'Statistiques',
}
</script>

<style>

</style>