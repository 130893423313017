export default {
  'add': 'ajouter un utilisateur',
  'create': 'créer un utilisateur',
  'create-button': 'créer l\'utilisateur',
  'delete-success': 'L\'utilisateur {name} a bien été supprimé !',
  'create-success': 'L\'utilisateur {name} a bien été créé !',
  'create-title': 'formulaire de création d\'un utilisateur pour {name}',
  'delete': 'supprimer l\'utilisateur {name} ?',
  'informations': 'information du l\'utilisateur {name}'
}
