<template>
  <div>
    <label
      v-if="label"
      :for="inputId" 
      class="block text-sm font-medium text-gray-700 capitalize-first"
    >
      {{ label }}
    </label>
    <div class="mt-1 relative rounded-md shadow-sm">
      <input
        ref="inputEl"
        v-test="{
          name: 'input',
          value: id
        }"
        :id="inputId" 
        :type="type" 
        :name="name" 
        :placeholder="placeholder" 
        :value="inputValue" 
        :min="min" 
        :max="max" 
        aria-invalid="true"
        :aria-describedby="inputId + '-error'"
        :class="{
          'border-red-600 text-red-900 placeholder-red-300 focus:ring-red-500 focus:border-red-500': hasError
        }"
        class="block w-full focus:outline-none sm:text-sm rounded-md"
        @input="onInput"
        @blur="onBlur"
      />
      <XIcon 
        v-if="hasValue && displayClearButton"
        v-test="'clear-button'"
        class="absolute text-black h-6 w-6 p-1 top-2 right-2 cursor-pointer"
        @click="onClickClearButton"
      />
      <div
        v-if="hasError" 
        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
      >
        <ExclamationCircleIcon 
          class="h-5 w-5 text-red-500" 
          aria-hidden="true" 
        />
      </div>
    </div>
    <p
      v-if="hasError"
      :id="inputId + '-error'"
      class="mt-2 text-sm text-red-600" 
    >
      {{ error }}
    </p>
  </div>
</template>

<script>
import { ExclamationCircleIcon, XIcon } from '@heroicons/vue/solid'

export default {
  props: {
    id: {
      type: String,
      default: '',
      validator (value) {
        return !!value
      }
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'name'
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    min: {
      type: [String, Number],
      default: ''
    },
    max: {
      type: [String, Number],
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    displayClearButton: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ExclamationCircleIcon,
    XIcon
  },
  data () {
    return {
      inputValue: this.value
    }
  },
  computed: {
    inputId () {
      return 'ui-input-' + this.id
    },
    hasValue () {
      return !!this.inputValue
    },
    hasError () {
      return !!this.error
    }
  },
  methods: {
    // STRANGE FACT: @input is automaticaly emited not @blur
    onInput ({ target: { value } }) {
      this.inputValue = value
    },
    onBlur (event) {
      this.$emit('blur', event)
    },
    onClickClearButton () {
      const { inputEl } = this.$refs

      this.inputValue = ''

      // Trigger @input
      setTimeout(() => {
        const event = new Event('input', {
          bubbles: true,
          cancelable: true
        })
        inputEl.dispatchEvent(event)
      })
    }
  }
}
</script>