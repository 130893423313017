export default ({ axios }) => {
  return {
    get ({ entityId, clientId }) {
      if (entityId) {
        return axios.get('/entity/' + entityId)
      } else if (clientId) {
        return axios.get('/entity?clientId=' + clientId)
      }

      return axios.get('/entity')
    },
    create (data) {
      return axios.post('/entity', data)
    },
    put (entityId, data) {
      return axios.put('/entity/' + entityId, data)
    },
    duplicate (data) {
      return axios.put('/entity/duplicate', data)
    },
    delete (entityId) {
      return axios.delete('/entity/' + entityId)
    }
  }
}