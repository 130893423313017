const langs = require.context('./lang', true, /index.js/)

const translations = {}

langs.keys().forEach((path) => {
  const splittedPath = path.split('/')
  const lang = splittedPath[1]
  const data = langs(path).default
  
  translations[lang] = data
})

export default translations
