import { createApp } from 'vue/dist/vue.esm-bundler'
import moment from 'moment'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { createI18n } from 'vue-i18n'
import notifications from '@kyvg/vue3-notification'
import App from './App.vue'
import api from './api'
import router from './router'
import store from './store/index.js'
import lodash from './assets/mixins/lodash'
import documentTitle from './assets/mixins/document-title'
import globalUtilities from './assets/mixins/global-utilities'
import translations from './locales'
import testDirective from './assets/directives/test'
import './index.css'

const i18n = createI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: translations
})

const app = createApp(App)

moment.locale('fr')

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true
axios.defaults.crossDomain = true

app.use(VueAxios, axios)

app.use(notifications)

app.use(router)

const $ct = (...args) => globalUtilities.capitalize(i18n.global.t(...args))
const $ctc = (...args) => globalUtilities.capitalize(i18n.global.tc(...args))

app.config.globalProperties.$moment = moment
app.config.globalProperties.$i18n = i18n
app.config.globalProperties.$api = api(app)
app.config.globalProperties.$lodash = lodash
app.config.globalProperties.$ct = $ct
app.config.globalProperties.$ctc = $ctc
app.config.globalProperties.$notify.error = () => app.config.globalProperties.$notify({ type: 'error', text: $ct('errors.unknown') })

app.use(i18n)

app.use(store(app, router))

app.mixin({
  methods: {
    ...lodash,
    ...globalUtilities
  }
})

app.directive('test', testDirective)

app.mixin(documentTitle)

app.mount('#app')
