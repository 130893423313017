<template>
  <div class="border-b border-gray-200">
    <div class="sm:flex sm:items-center">
      <h1 class="pb-4 text-xl leading-6 font-medium text-gray-900">
        {{ getTranslation(currentLink) }}
      </h1>
      <div
        v-if="hasSublinks" 
        class="pb-4 mt-4 sm:mt-0 sm:ml-10"
      >
        <nav class="-mb-px flex space-x-8">
          <router-link 
            v-for="sublink in currentLink.sublinks" 
            :key="sublink.t" 
            :to="sublink.to" 
            :class="[
              isCurrentSublink(sublink) ? 'border-blue-800 text-blue-800' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300', 
              'flex items-center whitespace-nowrap px-1 border-b-2 font-medium'
            ]"
          >
            <component 
              :is="sublink.icon" 
              :class="[
                isCurrentSublink(sublink) ? 'text-blue-800' : 'text-gray-400 group-hover:text-gray-300', 
                'mr-4 flex-shrink-0 h-5 w-5'
              ]" 
              aria-hidden="true" 
            />
            {{ getTranslation(sublink) }}
          </router-link>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import navigationMixin from '../assets/mixins/navigation'

export default {
  mixins: [navigationMixin]
}
</script>