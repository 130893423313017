<template>
  <h1 class="pb-4 text-lg font-medium capitalize-first">
    {{ $t('clients.create-title') }}
  </h1>
  <ui-form 
    :sections="form.sections"
    :save-button-name="$t('create-account')"
    @input="onInputForm"
    @submit="onSubmitForm"
  />
</template>

<script>
import UiForm from '../components/ui-form.vue'

import clientsMixin from '../assets/mixins/clients'
import formMixin from '../assets/mixins/form'

export default {
  title () {
    return this.$ct('clients.create-title')
  },
  mixins: [formMixin, clientsMixin],
  components: {
    UiForm
  },
  data () {
    return {
      form: {
        sections: [
          {
            key: 'options',
            title: this.$t('account-options'),
            disabled: false,
            inputs: [
              {
                key: 'account-type',
                label: this.$t('account-type'),
                type: 'radio',
                selected: 'classic', // is option value
                required: true,
                options: [
                  {
                    key: 'classic',
                    value: 'classic',
                    label: this.$t('classic-account-type'),
                    description: this.$t('clients.account-type-classic-description')
                  },
                  // pas de feature de subscription pour le moment
                  // {
                  //   key: 'free',
                  //   value: 'free',
                  //   label: this.$t('free-account-type'),
                  //   description: this.$t('clients.account-type-free-description')
                  // },
                  {
                    key: 'sandbox',
                    value: 'sandbox',
                    label: this.$t('sandbox-account-type'),
                    description: this.$t('clients.account-type-sandbox-description')
                  }
                ]
              },
              {
                key: 'trialEndDate',
                label: this.$t('trial-end-date'),
                type: 'date',
                value: '',
                min: this.$moment().add(1, 'days').format('YYYY-MM-DD'),
                required: true,
                display: false
              },
              {
                key: 'clients',
                type: 'list',
                value: '',
                list: [],
                label: this.$t('clients.duplicate'),
                required: true,
                display: false
              }
            ]
          },
          {
            key: 'informations',
            title: 'Informations',
            disabled: false,
            display: true,
            inputs: [
              {
                key: 'name',
                label: this.$t('name'),
                type: 'text',
                value: '',
                required: true,
                display: true
              },
              {
                key: 'precisionUnit',
                label: this.$t('unit'),
                type: 'list',
                value: '',
                required: true,
                display: true
              },
              {
                key: 'monthFiscalStart',
                label: this.$t('fiscal-start'),
                type: 'month-selector',
                month: '',
                required: true,
                display: true
              }
            ]
          }
        ]
      }
    }
  },
  computed: {
    informationsSection () {
      return this.getSection('informations')
    },
    clientsInput () {
      return this.getInput('clients')
    },
    accountTypeInput () {
      return this.getInput('account-type')
    },
    trialEndDateInput () {
      return this.getInput('trialEndDate')
    },
    nameInput () {
      return this.getInput('name')
    },
    precisionUnitInput () {
      return this.getInput('precisionUnit')
    },
    monthFiscalStartInput () {
      return this.getInput('monthFiscalStart')
    }
  },
  async created () {
    const clients = await this.fetchClients()

    this.clientsInput.list = clients
  },
  methods: {
    onInputForm ({ input, data }) {
      const { informationsSection, trialEndDateInput, clientsInput, nameInput, precisionUnitInput, monthFiscalStartInput } = this

      if (input.key === 'account-type') {
        const selectedAccountType = data.key

        informationsSection.display = true
        trialEndDateInput.display = false
        clientsInput.display = false
        nameInput.display = true
        precisionUnitInput.display = true
        monthFiscalStartInput.display = true

        if (selectedAccountType === 'free') {
          trialEndDateInput.display = true
        } else if (selectedAccountType === 'sandbox') {
          clientsInput.display = true
          precisionUnitInput.display = false
        }
      }
    },
    async onSubmitForm () {
      // Mapping

      try {
        const { createClient, accountTypeInput, clientsInput, trialEndDateInput, nameInput, precisionUnitInput, monthFiscalStartInput } = this

        const accountType = accountTypeInput.selected

        const subscribeEndDate = this.$moment(trialEndDateInput.value).format()
        const name = nameInput.value
        const precisionUnit = precisionUnitInput.value
        const monthFiscalStart = this.$moment().month(monthFiscalStartInput.month).format('M')


        let newClient = {}

        if (accountType === 'sandbox') {
          const clientId = clientsInput.value
          const selectedClient = this.getClient(clientId)

          newClient = {
            accountType,
            name,
            monthFiscalStart,
            clientIdSource: selectedClient.id
          }
        } else if (accountType === 'classic') {
          newClient = {
            accountType,
            name,
            monthFiscalStart,
            precisionUnit
          }
        } else if (accountType === 'free') {
          newClient = {
            accountType,
            name,
            monthFiscalStart,
            precisionUnit,
            subscribeEndDate
          }
        }

        const { id } = await createClient(newClient)

        // translation
        this.$notify({ type: 'success', text: this.$t('clients.create-success', { name: newClient.name }) })

        this.$router.push('/clients/' + id + '?from_path=/clients')
      } catch (err) {
        // translation
        this.$notify.error()
      }
    }
  }
}
</script>

<style>

</style>