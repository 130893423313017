<template>
  <ui-select 
    :label="label ? label : displayLabel ? 'Clients' : ''"
    :options="client.list"
    :default-selected="defaultClient"
    :disabled="disabled"
    :multiple="multiple"
    :display-clear-button="displayClearButton"
    class="w-80"
  />
</template>

<script>
import UiSelect from './ui-select.vue'

export default {
  props: {
    defaultClient: {
      type: [String, Array],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => ([])
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: false
    },
    displayClearButton: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UiSelect
  },
  data () {
    return {
      client: {
        list: []
      }
    }
  },
  watch: {
    'list': {
      handler () {
        this.initClientList()
      },
      immediate: true
    }
  },
  methods: {
    initClientList () {
      const clientList = this.list.map((client) => {
        return {
          key: client.id,
          value: client.id,
          label: client.name,
          client
        }
      })

      this.client.list = clientList
    }
  }
}
</script>