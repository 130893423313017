<template>
  <form
    v-test="'form'"
    @submit.prevent="onSubmit"
  >
    <template
      v-for="(section, index) in getDisplayableSections(sections)"
      :key="section.key"
    >
      <div
        v-test="{
          name: 'section',
          value: section.key
        }"
        :class="{
          'pointer-events-none opacity-40': section.disabled
        }"
      >
        <div class="py-3 pl-6">
          <h3 class="leading-6 text-blue-700 flex">
            <template v-if="getDisplayableSections(sections).length > 1">
              {{ index + 1 }}.
            </template>
            <span class="capitalize-first ml-1">
              {{ section.title }}
            </span> 
          </h3>
          <p class="mt-1 text-sm text-gray-600 capitalize-first">
            {{ section.subtitle }}
          </p>
        </div>
        <div 
          class="mt-5 md:mt-0 md:col-span-2"
          @submit.prevent
        >
          <div class="px-4 py-5 space-y-6 bg-white sm:p-6 sm:rounded-md shadow">
            <div 
              v-for="(input, index) in getDisplayableInputs(section.inputs)"
              :key="input.key"
              :id="'input-container-' + input.key"
            >
              <template v-if="input.type === 'radio'">
                <div
                  class="block pb-4 text-sm font-medium text-gray-700 capitalize-first"
                >
                  {{ getLabel(input) }}
                </div>
                <ui-radio
                  :default-selected="input.selected"
                  :options="input.options"
                  @select="onSelectRadio(section, input, $event)"
                />
              </template>
              <template v-else-if="input.type === 'options'">
                <div
                  class="block pb-4 text-sm font-medium text-gray-700 capitalize-first"
                >
                  {{ getLabel(input) }}
                </div>
                <ui-options 
                  :options="input.options"
                />
              </template>
              <template v-else-if="input.type === 'list'">
                <select-job-function
                  v-if="input.key === 'jobFunction'"
                  v-bind="input"
                  :display-label="true"
                  :label="getLabel(input)"
                  :default-job-function="input.value"
                  @clear="onClearOption(section, input)"
                  @select="onSelectOption(section, input, $event)"
                />
                <select-business-segment
                  v-if="input.key === 'businessSegment'"
                  v-bind="input"
                  :display-label="true"
                  :label="getLabel(input)"
                  :default-business-segment="input.value"
                  @clear="onClearOption(section, input)"
                  @select="onSelectOption(section, input, $event)"
                />
                <select-precision-unit
                  v-if="input.key === 'precisionUnit'"
                  v-bind="input"
                  :display-label="true"
                  :label="getLabel(input)"
                  :default-precision-unit="input.value"
                  @clear="onClearOption(section, input)"
                  @select="onSelectOption(section, input, $event)"
                />
                <select-duration
                  v-if="input.key === 'duration'"
                  v-bind="input"
                  :display-label="true"
                  :label="getLabel(input)"
                  :default-duration="input.value"
                  @clear="onClearOption(section, input)"
                  @select="onSelectOption(section, input, $event)"
                />
                <select-entity
                  v-if="input.key === 'entities'"
                  v-bind="input"
                  :display-label="true"
                  :label="getLabel(input)"
                  :default-entity="input.value"
                  @clear="onClearOption(section, input)"
                  @select="onSelectOption(section, input, $event)"
                />
                <select-client
                  v-if="input.key === 'clients'"
                  v-bind="input"
                  :display-label="true"
                  :label="getLabel(input)"
                  :default-client="input.value"
                  @clear="onClearOption(section, input)"
                  @select="onSelectOption(section, input, $event)"
                />
                <select-country
                  v-if="input.key === 'country'"
                  v-bind="input"
                  :display-label="true"
                  :label="getLabel(input)"
                  :default-country="input.value"
                  @clear="onClearOption(section, input)"
                  @select="onSelectOption(section, input, $event)"
                />
                <select-user-role
                  v-if="input.key === 'user-role'"
                  v-bind="input"
                  :display-label="true"
                  :title="input.title"
                  :default-user-role="input.value"
                  @clear="onClearOption(section, input)"
                  @select="onSelectOption(section, input, { value: $event })"
                />
              </template>
              <template v-else-if="input.type === 'toggle'">
                <div
                  class="block text-sm font-medium text-gray-700 capitalize-first"
                >
                  {{ getLabel(input) }}
                </div>
                <ui-toggle
                  :id="input.key"
                  :value="input.value"
                  :class="{
                    'ml-2': !!getLabel(input)
                  }"
                  class="mt-2"
                  @input="onInput(section, input, { target : { value: $event } })"
                />
              </template>
              <template v-else-if="input.type === 'month-year' || input.type === 'month-selector'">
                <div
                  class="block pb-4 text-sm font-medium text-gray-700 capitalize-first"
                >
                  {{ getLabel(input) }}
                </div>
                <ui-month-picker 
                  :default-month="input.month"
                  :default-year="input.year"
                  :disable-year="input.type === 'month-selector'"
                  @select="onSelectMonthPicker(section, input, $event)"
                />
              </template>
              <ui-input
                v-else
                :id="input.key"
                :label="getLabel(input)"
                :type="input.type"
                :value="input.value"
                :min="input.min"
                :max="input.max"
                :class="input.classList"
                class="w-96"
                :error="input.error"
                @input="onInput(section, input, $event)"
              />
              <!-- <div
                v-if="hasError(input)" 
                class="my-2 text-sm text-red-600 capitalize-first"
              >
                {{ getError(input) }}
              </div> -->
              <div 
                v-if="index !== getDisplayableInputs(section.inputs).length - 1"
                class="pt-6"
              >
                <div class="border-t border-gray-100" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div 
        v-if="index !== getDisplayableSections(sections).length - 1"
        class="hidden sm:block" 
        aria-hidden="true"
      >
        <div class="py-5">
          <div class="border-t border-gray-200" />
        </div>
      </div>
    </template>
    <p class="text-xs text-gray-400 m-2 text-right">
      * {{ $t('required-fields') }}
    </p>
    <div class="flex flex-col items-center">
      <ui-button
        v-test="'submit-button'"
        :name="submitButtonName || $ct('validate')"
        type="submit"
        class="m-4 self-center"
      />
    </div>
  </form>
</template>

<script>
import UiButton from '../components/ui-button.vue'
import UiRadio from '../components/ui-radio.vue'
import UiOptions from '../components/ui-options.vue'
import UiToggle from '../components/ui-toggle.vue'
import UiInput from '../components/ui-input.vue'
import UiMonthPicker from '../components/ui-month-picker.vue'
import SelectJobFunction from './select-job-function.vue'
import SelectBusinessSegment from './select-business-segment.vue'
import SelectPrecisionUnit from './select-precision-unit.vue'
import SelectDuration from './select-duration.vue'
import SelectEntity from './select-entity.vue'
import SelectClient from './select-client.vue'
import SelectCountry from './select-country.vue'
import SelectUserRole from './select-user-role.vue'

/* Section explanations : 
 *
 * Display attribute permit to undisplay all his inputs
 * Disabled attribute is just for visual effect, and didnt undisplay his inputs
 */

export default {
  props: {
    sections: {
      type: Array,
      default: () => ([])
    },
    submitButtonName: {
      type: String, 
      default: null
    }
  },
  emits: ['input', 'input.error', 'submit', 'error'],
  components: {
    UiButton,
    UiRadio,
    UiOptions,
    UiToggle,
    UiInput,
    UiMonthPicker,
    SelectJobFunction,
    SelectBusinessSegment,
    SelectPrecisionUnit,
    SelectDuration,
    SelectEntity,
    SelectClient,
    SelectCountry,
    SelectUserRole
  },
  created () {
    this.initInputs()
  },
  methods: {
    initInputs () {
      const { sections } = this

      sections.forEach(({ inputs }) => {
        inputs.forEach((input) => {
          if (input.error === undefined) {
            input.error = ''
          }
        })
      })
    },
    getDisplayableSections (sections) {
      return sections.filter(({ display }) => display !== false)
    },
    getDisplayableInputs (inputs) {
      return inputs.filter(({ display }) => display !== false)
    },
    getLabel (input) {
      if (input.required && input.label) {
        return input.label + '*'
      }

      return input.label || ''
    },
    onSelectMonthPicker (section, input, { key, option }) {
      input[key] = option.value
      input.error = ''

      this.$emit('input', { section, input, data: { key, option } })
    },
    onSelectRadio (section, input, $event) {
      input.selected = $event.value

      input.error = ''

      this.$emit('input', { section, input, data: $event })
    },
    onSelectOption (section, input, event) {
      const { value, selected } = event
      if (input.multiple) {
        if (!Array.isArray(input.value)) {
          if (input.value) {
            input.value = [input.value]
          } else {
            input.value = []
          }
        }

        if (selected) {
          if (!input.value.includes(value)) {
            input.value.push(value)
          }
        } else {
          input.value = input.value.filter(_value => _value !== value)
        }
      } else {
        input.value = value
      }

      input.error = ''

      this.$emit('input', { section, input, data: { value, selected } })
    },
    onClearOption (section, input) {
      if (input.multiple) {
        input.value = []
      } else {
        input.value = ''
      }

      this.$emit('input:clear', { section, input })
    },
    onInput (section, input, { target: { value } }) {
      input.value = value
      input.error = ''

      this.$emit('input', { section, input, data: value })
    },
    onSubmit () {
      try {
        this.checkRequiredInputs()

        this.$emit('submit')
      } catch (err) {
        this.$emit('error', err)
      }
    },
    checkRequiredInputs () {
      const displayableSections = this.getDisplayableSections(this.sections)

      const errorCode = 'form.input.not-filled'

      const throwError = (error) => { throw new Error(error || errorCode) }

      for (const section of displayableSections) {
        let checkPasswordDone = false

        const displayableInputs = this.getDisplayableInputs(section.inputs)

        for (const input of displayableInputs) {
          let hasError = false

          if (input.required && input.display !== false) {
            if (input.type === 'radio') {
              if (!input.selected) {
                hasError = true
              }
            } else if (input.type === 'month-year') {
              if (!input.month || !input.year) {
                hasError = true
              }
            } else if (input.type === 'month-selector') {
              if (!input.month) {
                hasError = true
              }
            } else if (!input.value) {
              hasError = true
            }

            if (hasError) {
              this.focusInput(input)

              input.error = this.$ct('ui-form.please-fill-this-field')
              
              this.$emit('input.error', { input, error: { code: errorCode } })

              throwError()
            }
          }
        }

        if (!checkPasswordDone) {
          const passWordInput = displayableInputs.filter(({ defaultType }) => defaultType === 'password')

          if (passWordInput) {
            checkPasswordDone = true
            
            if (passWordInput.length === 2) {
              const password = passWordInput[0]
              const retypePassword = passWordInput[1]

              if (password.value !== retypePassword.value) {
                
                retypePassword.error = this.$ct('ui-form.passwords-must-be-the-same')

                this.focusInput(retypePassword)

                this.$emit('input.error', { retypePassword, error: { code: this.$ct('ui-form.passwords-must-be-the-same') } })
                
                throwError()
              }
            }
          }
        }
      }
    },
    focusInput (input) {
      const inputContainerEl = document.querySelector('#input-container-' + input.key)
      const mainPageEl = document.querySelector('#main-page')

      if (inputContainerEl && mainPageEl) {
        mainPageEl.scroll({
          behavior: 'smooth',
          top: inputContainerEl.offsetTop - 50
        })
      }
    },
    hasError (input) {
      return !!input.error
    },
    getError (input) {
      return input.error
    }
  }
}
</script>

<style>

</style>