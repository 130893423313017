<template>
  <ui-select 
    :label="label ? label : displayLabel ? $ct('job-function') : ''"
    :options="jobFunction.list"
    :default-selected="defaultJobFunction"
    :disabled="disabled"
    class="w-80"
  />
</template>

<script>
import UiSelect from './ui-select.vue'

// translation
const jobFunctions = [
  'Directeur Financier',
  'Trésorier',
  'Responsable BU ou département',
  'CEO / Fondateur',
  'Responsable Financier',
  'CEO / Founder',
  'BU or department manager',
  'Contrôleur de gestion',
  'Autre'
]

export default {
  props: {
    defaultJobFunction: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UiSelect
  },
  data () {
    return {
      jobFunction: {
        list: []
      }
    }
  },
  created () {
    this.initJobFunctionList()
  },
  methods: {
    initJobFunctionList () {
      const jobFunctionList = jobFunctions.map((jobFunction, index) => {
        return {
          key: index,
          value: jobFunction,
          label: jobFunction,
        }
      })

      this.jobFunction.list = jobFunctionList
    }
  }
}
</script>