function transformUserData (user) {
  const attrs = [
    {
      key: 'id',
      default: ''
    },
    {
      key: 'roles',
      default: []
    },
    {
      key: 'firstname',
      default: ''
    },
    {
      key: 'lastname',
      default: ''
    },
    {
      key: 'email',
      default: ''
    },
    {
      key: 'phoneNumber',
      default: ''
    },
    {
      key: 'jobFunction',
      default: ''
    },
    {
      key: 'languageId',
      default: ''
    },
    {
      key: 'lastConnection',
      default: ''
    },
    {
      key: 'createdAt',
      default: ''
    },
    {
      key: 'clientIds',
      default: []
    },
    {
      key: 'entityIds',
      default: []
    },
    {
      key: 'entityCount',
      default: 0
    }
  ]

  attrs.forEach((attr) => {
    let value = user[attr.key] !== undefined ? user[attr.key] : attr.default

    if (attr.key === 'firstname') {
      user.firstname = user.firstName

      return
    }

    if (attr.key === 'lastname') {
      user.lastname = user.lastName

      return
    }
 
    user[attr.key] = value
  })

  return user
}

export default ({ config: { globalProperties: { $api } } }) => ({
  state () {
    return {
      list: []
    }
  },
  getters: {
    getAllUsers (state) {
      return state.list
    },
    getUsers: (state) => (userIds) => {
      return state.list.filter(({ id }) => userIds.includes(id))
    },
    getUser: (state) => (userId) => {
      return state.list.find(({ id }) => id === userId)
    },
    getUsersByClientId: (state) => (clientId) => {
      return state.list.filter(({ clientIds }) => clientIds.includes(clientId))
    },
    getUsersByEntityId: (state) => (entityId) => {
      return state.list.filter(({ entityIds }) => entityIds.includes(entityId))
    }
  },
  actions: {
    async fetchUser ({ dispatch }, id) {
      dispatch('pushFetchingKey', 'user')

      const { data: user } = await $api.user.get(id)

      const newUser = dispatch('addUser', user)

      dispatch('removeFetchingKey', 'user')

      return newUser
    },
    async updateUser ({ commit, dispatch, getters }, { userId, data }) {
      dispatch('pushFetchingKey', 'update-user')

      await $api.user.put(userId, data)

      const user = getters.getUser(userId)

      commit('updateUser', { user, data })

      dispatch('removeFetchingKey', 'update-user')
    },
    async addClientToUser ({ dispatch }, { userId, clientId }) {
      dispatch('pushFetchingKey', 'add-client-to-user')

      await $api.user.addClient(userId, clientId)

      dispatch('pushClientIdToUser', { userId, clientId })

      dispatch('removeFetchingKey', 'add-client-to-user')
    },
    async deleteClientToUser ({ dispatch }, { userId, clientId }) {
      dispatch('pushFetchingKey', 'delete-client-to-user')

      await $api.user.deleteClient(userId, clientId)

      dispatch('removeClientIdToUser', { userId, clientId })

      dispatch('removeFetchingKey', 'delete-client-to-user')
    },
    async updateRole ({ dispatch }, { userId, roles }) {
      dispatch('pushFetchingKey', 'update-role')

      await $api.user.updateRole(userId, roles)

      dispatch('removeFetchingKey', 'update-role')
    },
    async createUser ({ dispatch }, user) {
      dispatch('pushFetchingKey', 'add-user')

      const { data } = await $api.user.addUser(user)

      dispatch('removeFetchingKey', 'add-user')

      return data
    },
    async deleteUser ({ dispatch }, { userId, clientId }) {
      dispatch('pushFetchingKey', 'delete-user')

      await $api.user.deleteUser(userId, clientId)

      dispatch('removeFetchingKey', 'delete-user')
    },
    addUser ({ commit, getters }, user) {
      user = transformUserData(user)

      const userInList = getters.getUser(user.id)
      
      if (userInList) {
        commit('updateUser', { user: userInList, data: user })
      } else {
        commit('addUser', user)
      }

      return user
    },
    removeUser ({ commit }, userId) {
      commit('removeUser', userId)
    },
    pushClientIdToUser ({ commit, getters }, { userId, clientId }) {
      const user = getters.getUser(userId)

      commit('pushClientIdToUser', { user, clientId })
    },
    removeClientIdToUser ({ commit, getters }, { userId, clientId }) {
      const user = getters.getUser(userId)

      commit('removeClientIdToUser', { user, clientId })
    }
  },
  mutations: {
    addUser (state, user) {
      state.list.push(user)
    },
    updateUser (state, { user, data }) {
      Object.keys(user).forEach((key) => {
        const value = data[key] === undefined ? user[key] : data[key]

        user[key] = value
      })
    },
    removeUser (state, userId) {
      state.list = state.list.filter(({ id }) => id === userId)
    },
    pushClientIdToUser (state, { user, clientId }) {
      if (!user.clientIds.includes(clientId)) {
        user.clientIds.push(clientId)
      }
    },
    removeClientIdToUser (state, { user, clientId }) {
      if (user.clientIds.includes(clientId)) {
        user.clientIds = user.clientIds.filter(_clientId => clientId !== _clientId)
      }
    }
  }
})
