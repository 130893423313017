export default () => ({
  state () {
    return {
      fetchingKeys: []
    }
  },
  getters: {
    isFetching (state) {
      return state.fetchingKeys.length > 0
    },
    isFetchingKey: (state) => (key) => {
      return state.fetchingKeys.includes(key)
    }
  },
  actions: {
    pushFetchingKey ({ commit }, key) {
      commit('pushFetchingKey', key)
    },
    removeFetchingKey ({ commit }, key) {
      commit('removeFetchingKey', key)
    },
    clearFetchingKey ({ commit }) {
      commit('clearFetchingKey')
    }
  },
  mutations: {
    pushFetchingKey (state, key) {
      state.fetchingKeys.unshift(key)
    },
    removeFetchingKey (state, key) {
      const index = state.fetchingKeys.findIndex((fetchingKey) => fetchingKey === key)

      state.fetchingKeys.splice(index, 1)
    },
    clearFetchingKey (state) {
      state.fetchingKeys = []
    }
  }
})
