export default {
  'add': 'add a user',
  'create': 'create a user',
  'create-button': 'create user',
  'delete-success': 'user {name} has been deleted !',
  'create-success': 'the user {name} has been created !',
  'create-title': 'user creation form for {name}',
  'delete': 'delete user {name}',
  'informations': 'user information {name}'
}
