import interceptors from './api.interceptors'

import auth from './api.auth'
import businessSegment from './api.business-segment'
import client from './api.client'
import country from './api.country'
import entity from './api.entity'
import user from './api.user'
import reporting from './api.reporting'

export default (context) => {
  interceptors(context)
  
  return {
    auth: auth(context),
    businessSegment: businessSegment(context),
    client: client(context),
    country: country(context),
    entity: entity(context),
    user: user(context),
    reporting: reporting(context)
  }
} 