export default {
  'add': 'ajouter une entité',
  'back-to-clients': 'retour sur la liste des clients',
  'copy': 'entité à copier',
  'create': 'créer une entité',
  'create-button': 'créer l\'entité',
  'create-title': 'formulaire de création d\'une entité pour {name}',
  'create-success': 'L\'entité {name} a bien été créé !',
  'delete': 'supprimer l\'entité {name} ?',
  'delete-success': 'L\'entité {name} a bien été supprimé !',
  'duplication': 'duplication entité',
  'duplication-title': 'formulaire de duplication entité',
  'duplication-total-description': 'concerne le mapping d’exploitation, le mapping de trésorerie, les liaisons et les lois de trésorerie.',
  'duplication-mapping-description': 'concerne le mapping d’exploitation et les liaisons.',
  'duplication-rules-description': 'concerne uniquement les lois de trésorerie.',
  'duplication-success': 'L\'entité {name} a bien été dupliqué !',
  'informations': 'informations de l\'entité {name}',
  'modify': 'entité à modifier',
  'new': 'nouvelle entité ?'
}
