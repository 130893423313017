<template>
  <router-link 
    v-if="to"
    :to="to"
    class="px-4 py-2 border border-transparent text-center font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 capitalize-first"
  >
    {{ name }}
  </router-link>
  <button 
    v-else
    type="button"
    class="px-4 py-2 border border-transparent text-center font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 capitalize-first"
  >
    {{ name }}
  </button>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    to: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>

</style>