export default ({ axios }) => {
  return {
    get (userId) {
      return axios.get('/user' + (userId ? '/' + userId : ''))
    },
    put (userId, data) {
      return axios.put('/user/' + userId, data)
    },
    addUser (data) {
      return axios.post('/user', data)
    },
    deleteUser (userId, clientId) {
      return axios.delete('/user/' + userId + '/' + clientId)
    },
    addClient (userId, clientId) {
      return axios.put('/user/' + userId + '/client', { actionType: 'add', clientId })
    },
    deleteClient (userId, clientId) {
      return axios.put('/user/' + userId + '/client', { actionType: 'delete', clientId })
    },
    updateRole (userId, roles) {
      return axios.put('/user/' + userId + '/role', { roles })
    }
  }
}