<template>
  <div 
    v-show="isFetching"
    v-test="'loader'"
    class="ui-loader" 
  />
</template>

<script>
import { Spinner } from 'spin.js'

export default {
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    isFetching () {
      return this.$store.getters.isFetching
    }
  },
  mounted () {
    const opts = {
      lines: 15, // The number of lines to draw
      length: 18, // The length of each line
      width: 4, // The line thickness
      radius: 15, // The radius of the inner circle
      scale: 0.5, // Scales overall size of the spinner
      corners: 1, // Corner roundness (0..1)
      speed: 0.8, // Rounds per second
      rotate: 37, // The rotation offset
      animation: 'spinner-line-shrink', // The CSS animation name for the lines
      direction: 1, // 1: clockwise, -1: counterclockwise
      color: 'blue', // CSS color or array of colors
      fadeColor: 'transparent', // CSS color or array of colors
      shadow: '0 0 1px transparent', // Box-shadow for the lines
      className: 'spinner', // The CSS class to assign to the spinner,
      position: 'static',
      ...this.options
    }

    const target = this.$el
    
    new Spinner(opts).spin(target)
  }
}
</script>

<style>
.ui-loader {
  height: 50px;
  width: 50px;

  position: fixed;

  z-index: 995;

  bottom: 30px;
  right: 90px;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: white;
  box-shadow: 0 0 15px rgba(0, 0, 0, .1);
  border-radius: 50%;
}

@keyframes spinner-line-fade-more {
  0%, 100% {
    opacity: 0; /* minimum opacity */
  }
  1% {
    opacity: 1;
  }
}

@keyframes spinner-line-fade-quick {
  0%, 39%, 100% {
    opacity: 0.25; /* minimum opacity */
  }
  40% {
    opacity: 1;
  }
}

@keyframes spinner-line-fade-default {
  0%, 100% {
    opacity: 0.22; /* minimum opacity */
  }
  1% {
    opacity: 1;
  }
}

@keyframes spinner-line-shrink {
  0%, 25%, 100% {
    /* minimum scale and opacity */
    transform: scale(0.5);
    opacity: 0.25;
  }
  26% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>
