export default {
  'account-type-classic-description': 'for normal use.',
  'account-type-free-description': 'for limited use.',
  'account-type-sandbox-description': 'for demo/test use.',
  'add': 'add a customer',
  'create-account': 'create a customer account',
  'create-success': 'the client {name} has been created !',
  'create-title': 'customer account creation form',
  'delete': 'delete the client {name}',
  'delete-description': 'are you sure you want to delete this client? all your data will be permanently deleted from our servers. this action cannot be undone.',
  'delete-success': 'the client {name} has been deleted !',
  'duplicate': 'duplicate client',
  'export-clients': 'download the list of clients',
  'export-entity': 'download the list of entities',
  'list': 'list of customers',
  'informations': '{name} client information'
}
