export default {
  methods: {
    getSection (key) {
      return this.form.sections.find(section => section.key === key)
    },
    getInput (key) {
      for (const section of this.form.sections) {
        for (const input of section.inputs) {
          if (input.key === key) {
            return input
          }
        }
      }
    }
  }
}
