export default ({ config: { globalProperties: { $api } } }) => ({
  state () {
    return {
      list: []
    }
  },
  getters: {
    getCountries (state) {
      return state.list
    }
  },
  actions: {
    async fetchCountries ({ commit }) {
      const { data: countries } = await $api.country.get()

      commit('setCountries', countries)
    }
  },
  mutations: {
    setCountries (state, countries) {
      state.list = countries
    }
  }
})
