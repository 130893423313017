export default {
  methods: {
    async fetchUser (userId) {
      return await this.$store.dispatch('fetchUser', userId)
    },
    async updateUser (userId, data) {
      return await this.$store.dispatch('updateUser', { userId, data })
    },
    async addClientToUser (userId, clientId) {
      return await this.$store.dispatch('addClientToUser', { userId, clientId })
    },
    async deleteClientToUser (userId, clientId) {
      return await this.$store.dispatch('deleteClientToUser', { userId, clientId })
    },
    async updateRole (userId, roles) {
      return await this.$store.dispatch('updateRole', { userId, roles })
    },
    getAllUsers () {
      return this.$store.getters.getAllUsers
    },
    getUsers (userIds) {
      return this.$store.getters.getUsers(userIds)
    },
    getUser (userId) {
      return this.$store.getters.getUser(userId)
    },
    getUsersByClientId (clientId) {
      return this.$store.getters.getUsersByClientId(clientId)
    },
    getUsersByEntityId (entityId) {
      return this.$store.getters.getUsersByEntityId(entityId)
    },
    getUserCreatedAt (user) {
      return this.$moment(user.createdAt, 'DD-MM-YYYY').format('ll')
    },
    async createUser (user) {
      return await this.$store.dispatch('createUser', user)
    },
    deleteUser (userId, clientId) {
      this.$store.dispatch('deleteUser', { userId, clientId })
    },
    addUser (user) {
      this.$store.dispatch('addUser', user)
    },
    removeUser (userId) {
      this.$store.dispatch('removeUser', userId)
    }
  }
}