<template>
  <ui-select
    :label="label ? label : displayLabel ? $ct('business-segment') : ''"
    :options="businessSegment.list"
    :default-selected="defaultBusinessSegment"
    :disabled="disabled"
    class="w-96"
  />
</template>

<script>
import businessSegmentMixin from '../assets/mixins/business-segment'

import UiSelect from './ui-select.vue'

export default {
  mixins: [businessSegmentMixin],
  props: {
    defaultBusinessSegment: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UiSelect
  },
  data () {
    return {
      businessSegment: {
        list: []
      }
    }
  },
  computed: {
    businessSegments () {
      return this.getBusinessSegments()
    }
  },
  watch: {
    'businessSegments': {
      handler () {
        this.initBusinessSegmentList()
      },
      immediate: true
    }
  },
  methods: {
    initBusinessSegmentList () {
      this.businessSegment.list = this.businessSegments.map(({ id, name }) => {
        return {
          key: id,
          value: id,
          label: name
        }
      })
    }
  }
}
</script>