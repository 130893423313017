import { UserGroupIcon, ViewListIcon, PlusIcon } from '@heroicons/vue/outline'

function pathIsMatching (path, link) {
  let { to, match } = link

  const pathNeedToMatch = str => str.includes(':id') || str.includes('...')
  const regex = str => str.split(':id').join('[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{4}-[0-9A-Fa-f]{12}').split('...').join('.*')

  if (match) {
    return !!match.find((_path) => pathIsMatching(path, { to: _path }))
  }

  if (pathNeedToMatch(to)) {
    return path.match(regex(to))
  } else {
    return path === to
  }
}

export default (app, router) => ({
  state () {
    return {
      list: [
        { 
          key: 'profile',
          t: 'your-profil', 
          to: '/profile', 
          display: false
        },
        { 
          key: 'clients',
          tc: ['client', 2], 
          to: '/clients',
          match: ['/clients', '/clients/...', '/entities/...', '/users/...'], 
          icon: UserGroupIcon,
          sublinks: [
            { 
              key: 'clients-list',
              t: 'clients.list', 
              to: '/clients',
              match: ['/clients', '/clients/:id', '/entities/:id', '/users/:id'],
              icon: ViewListIcon
            },
            { 
              key: 'clients-create',
              t: 'clients.create-account',
              to: '/clients/create', 
              icon: PlusIcon
            }
          ]
        }/*,
        { 
          key: 'statistics',
          t: 'statistics', 
          to: '/statistics', 
          icon: ChartBarIcon
        }*/
      ]
    }
  },
  getters: {
    getCurrentLink (state) {
      // $route.path still "/"...
      const { currentRoute: { value: { path } } } = router

      return state.list.find(link => pathIsMatching(path, link)) || {}
    },
    getCurrentSublink (state, getters) {
      // $route.path still "/"...
      const { currentRoute: { value: { path } } } = router
      const currentLink = getters.getCurrentLink

      if (currentLink?.sublinks?.length > 0) {
        return currentLink.sublinks.find((sublink) => pathIsMatching(path, sublink))
      }
    },
    displayableLinks (state) {
      return state.list.filter(({ display, to }) => display !== false && to)
    },
    getLink: (state) => (key) => {
      for (const link of state.list) {
        if (link.key === key) {
          return link
        } else if (link.sublinks?.length > 0) {
          for (const link of link.sublinks) {
            if (link.key === key) {
              return link
            }
          }
        }
      }
    },
    isCurrentLink: (state, getters) => (link) => {
      return link.key === getters.getCurrentLink?.key
    },
    isCurrentSublink: (state, getters) => (link) => {
      return link.key === getters.getCurrentSublink?.key
    }
  }
})
