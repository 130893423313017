const IS_AUTHENTICATED = 'IS_AUTHENTICATED'

export default ({ config: { globalProperties: { $api, $router } } }) => ({
  state () {
    return {
      user: JSON.parse(localStorage.getItem('user'))
    }
  },
  getters: {
    getCurrentUser (state) {
      return state.user
    }
  },
  actions: {
    async signIn ({ commit }, form) {
      try {
        const { data } = await $api.auth.signIn(form)

        localStorage.setItem(IS_AUTHENTICATED, true)

        localStorage.setItem('user', JSON.stringify(data))
  
        commit('updateCurrentUser', data)
      } catch (e) {
        throw new Error(e)
      }
    },
    async logout ({ commit, dispatch }) {
      try {
        await $api.auth.logout()

        localStorage.clear()

        commit('resetState')

        $router.push('/sign-in')
      } catch (e) {
        throw new Error(e)
      } finally {
        dispatch('clearFetchingKey')
      }
    }
  },
  mutations: {
    updateCurrentUser (state, data) {
      state.user = data
    },
    resetState (state) {
      const user = {}

      state.user = user
    }
  }
})