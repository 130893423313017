<template>
  <div class="bg-white shadow overflow-x-scroll sm:rounded-lg">
    <div class="flex flex-wrap items-center px-4 py-5 sm:px-6">
      <div class="m-2 ml-0">
        <h3 class="text-lg leading-6 font-medium text-gray-900 capitalize-first">
          <slot name="title" />
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500 capitalize-first">
          <slot name="subtitle" />
        </p>
      </div>
      <ui-button
        v-if="formHasDifference"
        v-test="'cancel-button'"
        :name="$ct('discard-changes')"
        class="ml-auto ui-button gray"
        @click="onClickCancelUpdatesButton"
      />
      <ui-button
        v-if="formHasDifference"
        v-test="'save-button'"
        :name="$ct('save')"
        class="ml-2"
        @click="onClickSaveButton"
      />
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
      <dl class="sm:divide-y sm:divide-gray-200">
        <div
          v-for="info in infos"
          v-test="{
            name: 'info',
            value: info.key
          }"
          :key="info.key"
          :class="{
            'pointer-events-none opacity-50': info.disabled
          }"
          class="sm:flex items-center py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt 
            :class="{
              'text-green-500 underline': infoIsEdited(info)
            }"
            class="text-sm font-medium text-gray-500 capitalize-first"
          >
            {{ info.title }}
          </dt>
          <template v-if="info.type === 'month-year' || info.type === 'month-selector'">
            <dd 
              class="group flex items-center mt-1 text-sm py-2 text-gray-900 sm:mt-0 sm:col-span-2 cursor-pointer"
            >
              <ui-month-picker 
                :default-month="info.month"
                :default-year="info.year"
                :disable-year="info.type === 'month-selector'"
                @select="onSelectMonthPickerInfo(info, $event)"
              />
            </dd>
          </template>
          <template v-else-if="info.type === 'toggle'">
            <dd 
              class="group flex items-center mt-1 text-sm py-2 text-gray-900 sm:mt-0 sm:col-span-2 cursor-pointer"
            >
              {{ info.label }}
              <ui-toggle
                :value="info.value"
                :class="{
                  'ml-2': !!info.label
                }"
                @input="onToggleInfo(info, $event)"
              />
            </dd>
          </template>
          <template v-else-if="info.type === 'list' || info.type === 'options'">
            <dd 
              class="group flex items-center mt-1 text-sm py-2 text-gray-900 sm:mt-0 sm:col-span-2 cursor-pointer"
            >
              <select-job-function
                v-if="info.key === 'jobFunction'"
                :default-job-function="info.value"
                @select="onSelectInfo(info, $event)"
              />
              <select-business-segment
                v-if="info.key === 'businessSegmentId'"
                :default-business-segment="info.value"
                @select="onSelectInfo(info, $event)"
              />
              <select-precision-unit
                v-if="info.key === 'precisionUnit'"
                :default-precision-unit="info.value"
                @select="onSelectInfo(info, $event)"
              />
              <select-duration
                v-if="info.key === 'duration'"
                :default-duration="info.value"
                @select="onSelectInfo(info, $event)"
              />
              <select-user-role
                v-if="info.key === 'role'"
                :default-role="info.value"
                @select="onSelectInfoOptions(info, $event)"
              />
            </dd>
          </template>
          <template v-else>
            <dd 
              v-if="!infoIsEditing(info)"
              v-test="{
                name: 'info-value',
                value: info.key
              }"
              :class="{
                'cursor-pointer': infoIsEditable(info)
              }"
              class="group flex mt-1 text-sm border-t-4 border-b-2 border-transparent py-2 text-gray-900 sm:mt-0 sm:col-span-2"
              @click="onClickInfo(info)"
            >
              {{ info.value || '-' }}
              <PencilIcon 
                v-if="infoIsEditable(info)"
                class="group-hover:text-gray-900 h-4 w-4 ml-2 text-gray-400" 
              />
            </dd>
            <ui-input 
              v-else
              :id="info.key"
              :value="info.value"
              @input="onInputInfo(info, $event)"
              @blur="onBlurInfo(info)"
            />
          </template>
        </div>
        <div 
          v-if="statistics.length > 0"
          class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500 capitalize-first">
            {{ $t('statistics') }}
          </dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <template 
              v-for="statisticGroup in statistics"
              :key="statisticGroup.key"
            >
              <div
                class="text-gray-500 pb-3 capitalize-first"
              >
                {{ statisticGroup.title }} :
              </div>
              <div
                v-test="{
                  name: 'statistic-group',
                  value: statisticGroup.key
                }"
                class="flex flex-wrap"
              >
                <div 
                  v-for="statistic in statisticGroup.values"
                  v-test="{
                    name: 'statistic',
                    value: statistic.key
                  }"
                  :key="statistic.key"
                  class="bg-blue-200 border-2 border-blue-300 text-xs py-1 px-2 m-1 rounded-full"
                >
                  {{ statistic.name }}
                  <strong class="text-base">
                    {{ statistic.value }}
                  </strong>
                </div>
              </div>
            </template>
          </dd>
        </div>
        <div 
          v-for="table in tables"
          v-test="{
            name: 'table',
            value: table.key
          }"
          :key="table.key"
          class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500 capitalize-first">
            {{ table.title }}
          </dt>
          <dd class="flex flex-col mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <div 
              v-if="tableHasButton(table)"
              class="flex flex-wrap"
            >
              <template
                v-for="button in table.buttons"
                :key="button.key"
              >
                <ui-button 
                  v-bind="button"
                  v-on="button.on || {}"
                  class="mr-2 mb-2"
                />
              </template>
            </div>
            <table class="min-w-full divide-y divide-gray-200 border border-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    v-for="header in table.headers"
                    :key="header.key" 
                    scope="col" 
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    {{ header.title }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="(row, index) in table.rows"
                  v-test="{
                    name: 'table-row',
                    value: row.key
                  }"
                  :key="index"
                  :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                >
                  <td 
                    v-for="value in row.values"
                    :key="value.key"
                    class="px-6 py-4 whitespace-nowrap text-sm text-left font-medium text-gray-900"
                  >
                    {{ value.value || '-' }}
                  </td>
                  <td 
                    v-for="action in getTableActions(table)"
                    :key="action.key"
                    class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                  >
                    <template v-if="action.if ? action.if(row) : true">
                      <router-link
                        v-if="action.to"
                        :to="getTableActionTo(action, row)" 
                        class="text-indigo-600 hover:text-indigo-900"
                      >
                        {{ action.name }}
                      </router-link>
                      <delete-button
                        v-else-if="action.key === 'delete'"
                        :title="action.on.title ? action.on.title(row) : action.title"
                        :description="action.on.description ? action.on.description(row) : action.description"
                        @yes="action.on && action.on.yes(row)"
                      />
                      <div
                        v-else
                        :class="action.classList"
                        @click="action.on && action.on.click(row)"
                      >
                        {{ action.name }}
                      </div>
                    </template>
                    <span 
                      v-else
                      class="opacity-20 pointer-events-none"
                    >
                      {{ action.name }}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
import clientsMixin from '../assets/mixins/clients'

import UiButton from '../components/ui-button.vue'
import UiToggle from '../components/ui-toggle.vue'
import UiInput from '../components/ui-input.vue'
import UiMonthPicker from '../components/ui-month-picker.vue'
import SelectJobFunction from './select-job-function.vue'
import SelectBusinessSegment from './select-business-segment.vue'
import SelectPrecisionUnit from './select-precision-unit.vue'
import SelectDuration from './select-duration.vue'
import SelectUserRole from './select-user-role.vue'
import DeleteButton from './delete-button.vue'

import { PencilIcon } from '@heroicons/vue/outline'

const isEditingSymbol = Symbol('isEditing')
const isEditedSymbol = Symbol('isEdited')

export default {
  props: {
    infos: {
      type: Array,
      default: () => ([])
    },
    statistics: {
      type: Array,
      default: () => ([])
    },
    tables: {
      type: Array,
      default: () => ([])
    }
  },
  mixins: [clientsMixin],
  components: {
    PencilIcon,
    UiButton,
    UiToggle,
    UiInput,
    UiMonthPicker,
    SelectJobFunction,
    SelectBusinessSegment,
    SelectPrecisionUnit,
    SelectDuration,
    SelectUserRole,
    DeleteButton
  },
  data () {
    return {
      form: {
        originalValues: {},
        newValues: {}
      }
    }
  },
  computed: {
    formHasDifference () {
      const { form } = this
      
      return !this._isEmpty(this.difference(form.newValues, form.originalValues))
    }
  },
  created () {
    this.initInfos()
  },
  methods: {
    initInfos () {
      const { infos, form: { originalValues } } = this

      infos.forEach((info) => {
        info[isEditingSymbol] = false
        info[isEditedSymbol] = false

        if (info.type === 'month-year') {
          originalValues[info.key] = {
            month: info.month,
            year: info.year
          }
        } else if (info.value !== undefined) {
          originalValues[info.key] = this._cloneDeep(info.value)
        }
      })
    },
    onClickInfo (info) {
      const isEditable = this.infoIsEditable(info)

      if (isEditable) {
        info[isEditingSymbol] = true
  
        setTimeout(() => {
          const input = document.querySelector('#ui-input-' + info.key)
  
          if (input) {
            input.focus()
            input.select()
          }
        })
      }
    },
    onInputInfo (info, event) {
      const { form } = this
      const { value } = event.target 

      info.value = value
      form.newValues[info.key] = value

      this.updateInfoIsEdited(info)
    },
    onBlurInfo (info) {
      info[isEditingSymbol] = false
    },
    onToggleInfo (info, value) {
      const { form } = this

      info.value = value
      form.newValues[info.key] = value

      this.updateInfoIsEdited(info)
    },
    onSelectMonthPickerInfo (info, { key, option }) {
      const { form } = this

      const value = String(option.key)

      info[key] = value

      if (!form.newValues[info.key]) {
        form.newValues[info.key] = {
          [key]: value
        }
      } else {
        form.newValues[info.key][key] = value
      }

      this.updateInfoIsEdited(info)
    },
    onSelectInfo (info, option) {
      const { form } = this
      const { value } = option
      
      const setNewValue = (value) => {
        form.newValues[info.key] = value
      }

      info.value = value
      setNewValue(value)

      this.updateInfoIsEdited(info)
    },
    onSelectInfoOptions (info, options) {
      const { form } = this
      
      const setNewValue = (value) => {
        form.newValues[info.key] = value
      }

      const values = options.map(( {value} ) => value)

      setNewValue(values)
      info.value = values

      this.updateInfoIsEdited(info)
    },
    updateInfoIsEdited (info) {
      const { form } = this

      info[isEditedSymbol] = this.difference(form.newValues, form.originalValues)[info.key] !== undefined
    },
    onClickSaveButton () {
      const { form } = this

      this.$emit('save', form)

      form.originalValues = {
        ...form.originalValues,
        ...form.newValues
      }

      form.newValues = {}
      
      this.infos.forEach((info) => {
        info[isEditedSymbol] = false
      })
    },
    onClickCancelUpdatesButton () {
      this.form.newValues = {}
      this.infos.forEach((info) => {
        const value = this.form.originalValues[info.key]

        if (value) {
          if (info.type === 'month-year') {
            info.month = value.month
            info.year = value.year
          } else if (info.type === 'month-selector') {
            info.month = value.month
          } else {
            info.value = value
          }
        }

        info[isEditedSymbol] = false
      })
    },
    getTableActions (table) {
      return table.headers.filter(({ type }) => type === 'action')
    },
    getTableActionTo (action, row) {
      if (typeof action.to === 'function') {
        return action.to(row)
      }

      return action.to
    },
    infoIsEditable (info) {
      return info.isEditable !== false
    },
    infoIsEditing (info) {
      return info[isEditingSymbol]
    },
    infoIsEdited (info) {
      return info[isEditedSymbol]
    },
    tableHasButton (table) {
      return table.buttons?.length > 0
    }
  }
}
</script>

<style>

</style>