<template>
  <ui-information-card
    v-if="display"
    :infos="infos"
    :tables="tables"
    @save="onSave"
  >
    <template #title>
      {{ $t('entities.informations', { name: entity.name }) }}
    </template>
    <template #subtitle>
      {{ $t('created-at', { date: getEntityCreatedAt(entity) }) }}
    </template>
  </ui-information-card>
  <div 
    v-else
    class="flex flex-col items-center"
  >
    <router-link
      to="/clients"
      class="text-blue-500 text-center underline w-full"
    >
      {{ $t('entities.back-to-clients') }}
    </router-link>
  </div>
</template>

<script>
import entitiesMixin from '../assets/mixins/entities'

import UiInformationCard from '../components/ui-information-card.vue'

export default {
  title () {
    return this.$ctc('entity', 1)
  },
  mixins: [entitiesMixin],
  components: {
    UiInformationCard
  },
  computed: {
    entityId () {
      return this.$route.params.id
    },
    currency () {
      return this.getCurrency(this.entity?.currencyId) || {}
    }
  },
  data () {
    return {
      display: false,
      entity: {},
      infos: [],
      tables: [],
      users: []
    }
  },
  async created () {
    try {
      this.entity = await this.fetchEntity(this.entityId)
      this.users = this.entity.users.map((user) => {
        return {
          id: user.userId,
          lastname: user.lastName,
          firstname: user.firstName
        }
      })

      this.initInfos()
      this.initTables()

      this.display = true
    } catch (err) {
      this.$notify.error()
    }
  },
  methods: {
    initInfos () {
      const { entity, currency } = this
      const momentFiscalStartMoment = this.$moment().startOf('year').add(entity.monthFiscalStart - 1, 'month')
      
      const infos = [
        {
          key: 'name',
          title: this.$t('name'),
          value: entity.name
        },
        {
          key: 'siret',
          title: this.$t('siret'),
          value: entity.siret
        },
        {
          key: 'address',
          title: this.$t('address'),
          value: entity.address
        },
        {
          key: 'businessSegmentId',
          title: this.$t('business-segment'),
          type: 'list',
          value: entity.businessSegmentId
        },
        {
          key: 'currency',
          title: this.$tc('currency', 1),
          value: currency.code
        },
        {
          key: 'monthFiscalStart',
          title: this.$t('fiscal-start'),
          type: 'month-selector',
          month: momentFiscalStartMoment.format('M')
        },
        {
          key: 'precisionUnit',
          title: this.$t('unit'),
          type: 'list',
          value: this.getPrecisionUnit(entity)
        }
      ]

      this.infos = infos
    },
    initTables () {
      const { entity, users } = this
      
      const tables = [
        {
          key: 'users',
          title: this.$ctc('user', users.length),
          headers: [
            {
              key: 'lastnameFirstname',
              title: this.$t('lastname') + ' ' + this.$t('firstname')
            },
            {
              key: 'show',
              type: 'action',
              name: this.$ct('display'),
              to (user) {
                return '/users/' + user.id + '?from_path=/entities/' + entity.id
              }
            }
          ],
          rows: users.map((user) => {
            return {
              id: user.id,
              values: [
                {
                  key: 'lastnameFirstname',
                  value: user.lastname + ' ' + user.firstname
                }
              ]
            }
          })
        }
      ]

      this.tables = tables
    },
    async onSave ({ newValues }) {
      try {
        const { entity } = this

        // en attente du business segment & devise & unit

        if (newValues.monthFiscalStart) {
          // merge month and year to be sure there is both values
          newValues.monthFiscalStart = this.$moment().month(Number(newValues.monthFiscalStart.month) - 1).format('M')
        }

        await this.updateEntity(entity.id, newValues)

        this.$notify(this.$ct('notification.update-success'))
      } catch (err) {
        this.$notify.error()
      }
    }
  }
}
</script>

<style>

</style>