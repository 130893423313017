export default {
  computed: {
    isNotApp () {
      return ['/', '/sign-in'].find(path => path === this.$route.path)
    },
    currentLink () {
      return this.$store.getters.getCurrentLink
    },
    displayableLinks () {
      return this.$store.getters.displayableLinks
    }
  },
  methods: {
    getLink (key) {
      return this.$store.getters.getLink(key)
    },
    isCurrentLink (link) {
      return this.$store.getters.isCurrentLink(link)
    },
    isCurrentSublink (link) {
      return this.$store.getters.isCurrentSublink(link)
    },
    hasSublinks (link) {
      return link.sublinks?.length > 0
    }
  }
}