import _merge from 'lodash.merge'

export default (files) => {
  let t = {}

  const constructObject = (names, translation) => {
    const obj = {}
    const name = names.shift().replace('.js', '')

    if (names.length > 0) {
      const _obj = constructObject(names, translation)

      obj[name] = _obj
    } else {
      obj[name] = translation
    }

    return obj
  }

  const mergeFromPath = (files, path) => {
    const names = path.split('/').filter(path => path !== '.')

    let translation = files(path).default

    if (names.length > 0) {
      translation = constructObject(names, translation)
    }

    merge(translation)
  }

  const merge = (translation) => {
    t = _merge(t, translation)
  }

  const init = files => files.keys().forEach(path => mergeFromPath(files, path))

  if (Array.isArray(files)) {
    files.forEach(files => init(files))
  } else {
    init(files)
  }

  return t
}
