<template>
  <div class="bg-white shadow sm:rounded-lg">
    <div class="flex flex-wrap items-center px-4 py-5 sm:px-6">
      <div class="m-2 ml-0">
        <h3 class="text-lg leading-6 font-medium text-gray-900 capitalize-first">
          {{ $t('settings') }}
        </h3>
      </div>
    </div>
    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
      <dl class="sm:divide-y sm:divide-gray-200">
        <div class="sm:flex items-center py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt
            class="text-sm font-medium text-gray-500 capitalize-first"
          >
            {{ $t('language') }}
          </dt>
          <dd 
            class="group flex items-center mt-1 text-sm py-2 text-gray-900 sm:mt-0 sm:col-span-2"
          >
            <select-language
              default-lang="fr"
              @select="onSelectLanguage"
            />
          </dd>
        </div>
      </dl>
    </div>
    <div class="flex border-t border-gray-200 px-4 py-5 sm:p-0">
      <ui-button
        :name="$t('disconnect')"
        class="mx-auto my-3 ui-button sign-out"
        @click="onClickSignOutButton"
      />
    </div>
  </div>
</template>

<script>
import authMixin from '../assets/mixins/auth'

import UiButton from '../components/ui-button.vue'
import SelectLanguage from '../components/select-language.vue'

export default {
  mixins: [authMixin],
  components: {
    UiButton,
    SelectLanguage
  },
  methods: {
    onClickSignOutButton () {
      this.logout()
    },
    onSelectLanguage (language) {
      const { value } = language

      this.$i18n.locale = value
      this.$moment.locale(value)
    }
  }
}
</script>

<style>

</style>