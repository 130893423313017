<template>
  <RadioGroup 
    v-test="'radio'"
    v-model="selected"
  >
    <div class="bg-white rounded-md -space-y-px">
      <RadioGroupOption
        as="template"
        v-for="(option, optionIdx) in options" 
        v-test="{
          name: 'option',
          value: option.key
        }"
        :key="option.key" 
        :value="option" 
        v-slot="{ checked, active }"
        @click="onSelect"
      >
        <div 
          :class="[
            {
              'rounded-tl-md rounded-tr-md': optionIdx === 0,
              'rounded-bl-md rounded-br-md': optionIdx === options.length - 1
            },
            checked ? 'bg-indigo-50 border-indigo-200 z-10' : 'border-gray-200'
          ]"
          class="relative border p-4 flex cursor-pointer focus:outline-none"
        >
          <span :class="[
              {
                'ring-2 ring-offset-2 ring-indigo-500': active
              },
              checked ? 'bg-indigo-600 border-transparent' : 'bg-white border-gray-300'
            ]" 
            class="h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center"
            aria-hidden="true"
          >
            <span class="rounded-full bg-white w-1.5 h-1.5" />
          </span>
          <div class="ml-3 flex flex-col">
            <RadioGroupLabel 
              as="span"
              v-test="{
                name: 'option-label',
                value: option.key
              }"
              :class="checked ? 'text-indigo-900' : 'text-gray-900'"
              class="block text-sm font-medium capitalize-first"
            >
              {{ option.label }}
            </RadioGroupLabel>
            <RadioGroupDescription 
              as="span"
              v-test="{
                name: 'option-description',
                value: option.key
              }"
              :class="checked ? 'text-indigo-700' : 'text-gray-500'"
              class="block text-sm capitalize-first"
            >
              {{ option.description }}
            </RadioGroupDescription>
          </div>
        </div>
      </RadioGroupOption>
    </div>
  </RadioGroup>
</template>

<script>
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'

export default {
  props: {
    defaultSelected: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => ([])
    }
  },
  components: {
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
  },
  data () {
    return {
      selected: ''
    }
  },
  created () {
    this.setSelectedOption()
  },
  methods: {
    onSelect () {
      this.$emit('select', this.selected)
    },
    setSelectedOption () {
      const { options, defaultSelected } = this

      this.selected = options.find(({ value }) => value === defaultSelected)
    }
  }
}
</script>
