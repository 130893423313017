<template>
  <div class="flex flex-col">
    <div class="flex">
      <ui-input 
        v-test="'client-search'" 
        id="search"
        :placeholder="$t('search')"
        class="self-start w-96 h-10 mb-3"
        :display-clear-button="true"
        @input="onInputSearch"
      />
      <ui-button
        id="export-clients"
        :name="$ct('clients.export-clients')"
        class="ml-auto ui-button h-10"
        @click="onClickExport"
      />
    </div>
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
          <table 
            class="min-w-full divide-y divide-gray-200"
            v-test="'client-table'" 
          >
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {{ $t('name') }}
                </th>
                <!-- // pas de feature de subscription pour le moment -->
                <!-- <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {{ $t('subscription') }}
                </th>
                <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  {{ $t('end-of-subscription') }}
                </th> -->
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">{{ $t('display') }}</span>
                </th>
                <th scope="col" class="relative px-6 py-3">
                  <span class="sr-only">{{ $t('delete') }}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr 
                v-for="(client, index) in paginatedClients" 
                :key="client.id"
                v-test="{
                  name: 'client-row',
                  value: client.id
                }" 
                :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
              >
                <td 
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                  v-test="'client-name'"
                >
                  {{ client.name }}
                </td>
                <!-- // pas de feature de subscription pour le moment -->
                <!-- <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ client.hasSubscription ? 'active' : '-' }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                  {{ getSubscribeEndDate(client) }}
                </td> -->
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <router-link 
                    v-test="'client-display'"
                    :to="'/clients/' + client.id + '?from_path=/clients'" 
                    class="text-indigo-600 hover:text-indigo-900"
                  >
                    <div class="capitalize-first">
                      {{ $t('display') }}
                    </div>
                  </router-link>
                </td>
                <td class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                  <delete-button
                    :title="$ct('clients.delete', { name: client.name })"
                    :description="$t('clients.delete-description')"
                    @yes="onDelete(client)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <ui-pagination
      :list="filteredClients"
      @list:paginate="onPaginateList"
    />
  </div>
</template>

<script>
import fileSaver from 'file-saver'

import authMixin from '../assets/mixins/auth'
import clientsMixin from '../assets/mixins/clients'
import reportingMixin from '../assets/mixins/reporting'

import UiButton from '../components/ui-button.vue'
import UiPagination from '../components/ui-pagination.vue'
import UiInput from '../components/ui-input.vue'
import DeleteButton from '../components/delete-button.vue'

export default {
  title () {
    return this.$ct('clients.list')
  },
  mixins: [authMixin, clientsMixin, reportingMixin],
  components: {
    UiButton,
    UiPagination,
    UiInput,
    DeleteButton
  },
  data () {
    return {
      filter: {
        search: ''
      },
      clients: [],
      paginatedClients: []
    }
  },
  async created () {
    this.clients = await this.fetchClients()
  },
  computed: {
    filteredClients () {
      return this.clients.filter(({ name }) => name.toLowerCase().includes(this.filter.search.toLowerCase()))
    }
  },
  methods: {
    onInputSearch ({ target: { value } }) {
      this.filter.search = value
    },
    async onClickExport () {
      try {
        const { getCurrentUserId } = this
        const { headers, data } = await this.getClientsReporting({ userId: getCurrentUserId })
        const blob = new Blob([data], { type: 'application/vnd.ms-excel;charset=utf-8' })
        const disposition = headers['content-disposition']
        const regex = /filename=(.*)/gm
        const matches = regex.exec(disposition)
  
        fileSaver.saveAs(blob, decodeURIComponent(matches[1]))
      } catch {
        this.$notify.error()
      }
    },
    async onDelete (client) {
      try {
        await this.deleteClient(client.id)

        this.clients = this.clients.filter(({ id }) => id !== client.id)

        this.$notify({ type: 'success', text: this.$t('clients.delete-success', { name: client.name }) })
      } catch (err) {
        this.$notify.error()
      }
    },
    onPaginateList (list) {
      this.paginatedClients = list
    }
  }
}
</script>